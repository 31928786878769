/* eslint-disable no-plusplus */
import moment from 'moment';
import 'moment/locale/es';

export const scheduleWeekStructure = [
  {
    schedule: '07:50am-08:30am',
    0: {
      scheduled: false,
      reserved: false,
      isEditing: false,
      data: null,
    }, // lunes
    1: {
      scheduled: false,
      reserved: false,
      isEditing: false,
      data: null,
    }, // martes
    2: {
      scheduled: false,
      reserved: false,
      isEditing: false,
      data: null,
    }, // miercoles
    3: {
      scheduled: false,
      reserved: false,
      isEditing: false,
      data: null,
    }, // jueves
    4: {
      scheduled: false,
      reserved: false,
      isEditing: false,
      data: null,
    }, // viernes
    5: {
      scheduled: false,
      reserved: false,
      isEditing: false,
      data: null,
    }, // sabado
  },
  {
    schedule: '08:30am-09:10am',
    0: {
      scheduled: false,
      reserved: false,
      isEditing: false,
      data: null,
    }, // lunes
    1: {
      scheduled: false,
      reserved: false,
      isEditing: false,
      data: null,
    }, // martes
    2: {
      scheduled: false,
      reserved: false,
      isEditing: false,
      data: null,
    }, // miercoles
    3: {
      scheduled: false,
      reserved: false,
      isEditing: false,
      data: null,
    }, // jueves
    4: {
      scheduled: false,
      reserved: false,
      isEditing: false,
      data: null,
    }, // viernes
    5: {
      scheduled: false,
      reserved: false,
      isEditing: false,
      data: null,
    }, // sabado
  },
  {
    schedule: '09:10am-09:50am',
    0: {
      scheduled: false,
      reserved: false,
      isEditing: false,
      data: null,
    }, // lunes
    1: {
      scheduled: false,
      reserved: false,
      isEditing: false,
      data: null,
    }, // martes
    2: {
      scheduled: false,
      reserved: false,
      isEditing: false,
      data: null,
    }, // miercoles
    3: {
      scheduled: false,
      reserved: false,
      isEditing: false,
      data: null,
    }, // jueves
    4: {
      scheduled: false,
      reserved: false,
      isEditing: false,
      data: null,
    }, // viernes
    5: {
      scheduled: false,
      reserved: false,
      isEditing: false,
      data: null,
    }, // sabado
  },
  {
    schedule: '09:50am-10:30am',
    0: {
      scheduled: false,
      reserved: false,
      isEditing: false,
      data: null,
    }, // lunes
    1: {
      scheduled: false,
      reserved: false,
      isEditing: false,
      data: null,
    }, // martes
    2: {
      scheduled: false,
      reserved: false,
      isEditing: false,
      data: null,
    }, // miercoles
    3: {
      scheduled: false,
      reserved: false,
      isEditing: false,
      data: null,
    }, // jueves
    4: {
      scheduled: false,
      reserved: false,
      isEditing: false,
      data: null,
    }, // viernes
    5: {
      scheduled: false,
      reserved: false,
      isEditing: false,
      data: null,
    }, // sabado
  },
  {
    schedule: '10:30am-11:10am',
    0: {
      scheduled: false,
      reserved: false,
      isEditing: false,
      data: null,
    }, // lunes
    1: {
      scheduled: false,
      reserved: false,
      isEditing: false,
      data: null,
    }, // martes
    2: {
      scheduled: false,
      reserved: false,
      isEditing: false,
      data: null,
    }, // miercoles
    3: {
      scheduled: false,
      reserved: false,
      isEditing: false,
      data: null,
    }, // jueves
    4: {
      scheduled: false,
      reserved: false,
      isEditing: false,
      data: null,
    }, // viernes
    5: {
      scheduled: false,
      reserved: false,
      isEditing: false,
      data: null,
    }, // sabado
  },
  {
    schedule: '11:10am-11:50am',
    0: {
      scheduled: false,
      reserved: false,
      isEditing: false,
      data: null,
    }, // lunes
    1: {
      scheduled: false,
      reserved: false,
      isEditing: false,
      data: null,
    }, // martes
    2: {
      scheduled: false,
      reserved: false,
      isEditing: false,
      data: null,
    }, // miercoles
    3: {
      scheduled: false,
      reserved: false,
      isEditing: false,
      data: null,
    }, // jueves
    4: {
      scheduled: false,
      reserved: false,
      isEditing: false,
      data: null,
    }, // viernes
    5: {
      scheduled: false,
      reserved: false,
      isEditing: false,
      data: null,
    }, // sabado
  },
  {
    schedule: '11:50am-12:30pm',
    0: {
      scheduled: false,
      reserved: false,
      isEditing: false,
      data: null,
    }, // lunes
    1: {
      scheduled: false,
      reserved: false,
      isEditing: false,
      data: null,
    }, // martes
    2: {
      scheduled: false,
      reserved: false,
      isEditing: false,
      data: null,
    }, // miercoles
    3: {
      scheduled: false,
      reserved: false,
      isEditing: false,
      data: null,
    }, // jueves
    4: {
      scheduled: false,
      reserved: false,
      isEditing: false,
      data: null,
    }, // viernes
    5: {
      scheduled: false,
      reserved: false,
      isEditing: false,
      data: null,
    }, // sabado
  },
  {
    schedule: '12:30pm-01:10pm',
    0: {
      scheduled: false,
      reserved: false,
      isEditing: false,
      data: null,
    }, // lunes
    1: {
      scheduled: false,
      reserved: false,
      isEditing: false,
      data: null,
    }, // martes
    2: {
      scheduled: false,
      reserved: false,
      isEditing: false,
      data: null,
    }, // miercoles
    3: {
      scheduled: false,
      reserved: false,
      isEditing: false,
      data: null,
    }, // jueves
    4: {
      scheduled: false,
      reserved: false,
      isEditing: false,
      data: null,
    }, // viernes
    5: {
      scheduled: false,
      reserved: false,
      isEditing: false,
      data: null,
    }, // sabado
  },
  {
    schedule: '01:10pm-01:50pm',
    0: {
      scheduled: false,
      reserved: false,
      isEditing: false,
      data: null,
    }, // lunes
    1: {
      scheduled: false,
      reserved: false,
      isEditing: false,
      data: null,
    }, // martes
    2: {
      scheduled: false,
      reserved: false,
      isEditing: false,
      data: null,
    }, // miercoles
    3: {
      scheduled: false,
      reserved: false,
      isEditing: false,
      data: null,
    }, // jueves
    4: {
      scheduled: false,
      reserved: false,
      isEditing: false,
      data: null,
    }, // viernes
    5: {
      scheduled: false,
      reserved: false,
      isEditing: false,
      data: null,
    }, // sabado
  },
  {
    schedule: '01:50pm-02:30pm',
    0: {
      scheduled: false,
      reserved: false,
      isEditing: false,
      data: null,
    }, // lunes
    1: {
      scheduled: false,
      reserved: false,
      isEditing: false,
      data: null,
    }, // martes
    2: {
      scheduled: false,
      reserved: false,
      isEditing: false,
      data: null,
    }, // miercoles
    3: {
      scheduled: false,
      reserved: false,
      isEditing: false,
      data: null,
    }, // jueves
    4: {
      scheduled: false,
      reserved: false,
      isEditing: false,
      data: null,
    }, // viernes
    5: {
      scheduled: false,
      reserved: false,
      isEditing: false,
      data: null,
    }, // sabado
  },
  {
    schedule: '02:30pm-03:10pm',
    0: {
      scheduled: false,
      reserved: false,
      isEditing: false,
      data: null,
    }, // lunes
    1: {
      scheduled: false,
      reserved: false,
      isEditing: false,
      data: null,
    }, // martes
    2: {
      scheduled: false,
      reserved: false,
      isEditing: false,
      data: null,
    }, // miercoles
    3: {
      scheduled: false,
      reserved: false,
      isEditing: false,
      data: null,
    }, // jueves
    4: {
      scheduled: false,
      reserved: false,
      isEditing: false,
      data: null,
    }, // viernes
    5: {
      scheduled: false,
      reserved: false,
      isEditing: false,
      data: null,
    }, // sabado
  },
  {
    schedule: '03:10pm-03:50pm',
    0: {
      scheduled: false,
      reserved: false,
      isEditing: false,
      data: null,
    }, // lunes
    1: {
      scheduled: false,
      reserved: false,
      isEditing: false,
      data: null,
    }, // martes
    2: {
      scheduled: false,
      reserved: false,
      isEditing: false,
      data: null,
    }, // miercoles
    3: {
      scheduled: false,
      reserved: false,
      isEditing: false,
      data: null,
    }, // jueves
    4: {
      scheduled: false,
      reserved: false,
      isEditing: false,
      data: null,
    }, // viernes
    5: {
      scheduled: false,
      reserved: false,
      isEditing: false,
      data: null,
    }, // sabado
  },
  {
    schedule: '03:50pm-04:30pm',
    0: {
      scheduled: false,
      reserved: false,
      isEditing: false,
      data: null,
    }, // lunes
    1: {
      scheduled: false,
      reserved: false,
      isEditing: false,
      data: null,
    }, // martes
    2: {
      scheduled: false,
      reserved: false,
      isEditing: false,
      data: null,
    }, // miercoles
    3: {
      scheduled: false,
      reserved: false,
      isEditing: false,
      data: null,
    }, // jueves
    4: {
      scheduled: false,
      reserved: false,
      isEditing: false,
      data: null,
    }, // viernes
    5: {
      scheduled: false,
      reserved: false,
      isEditing: false,
      data: null,
    }, // sabado
  },
  {
    schedule: '04:30pm-05:10pm',
    0: {
      scheduled: false,
      reserved: false,
      isEditing: false,
      data: null,
    }, // lunes
    1: {
      scheduled: false,
      reserved: false,
      isEditing: false,
      data: null,
    }, // martes
    2: {
      scheduled: false,
      reserved: false,
      isEditing: false,
      data: null,
    }, // miercoles
    3: {
      scheduled: false,
      reserved: false,
      isEditing: false,
      data: null,
    }, // jueves
    4: {
      scheduled: false,
      reserved: false,
      isEditing: false,
      data: null,
    }, // viernes
    5: {
      scheduled: false,
      reserved: false,
      isEditing: false,
      data: null,
    }, // sabado
  },
  {
    schedule: '05:10pm-05:50pm',
    0: {
      scheduled: false,
      reserved: false,
      isEditing: false,
      data: null,
    }, // lunes
    1: {
      scheduled: false,
      reserved: false,
      isEditing: false,
      data: null,
    }, // martes
    2: {
      scheduled: false,
      reserved: false,
      isEditing: false,
      data: null,
    }, // miercoles
    3: {
      scheduled: false,
      reserved: false,
      isEditing: false,
      data: null,
    }, // jueves
    4: {
      scheduled: false,
      reserved: false,
      isEditing: false,
      data: null,
    }, // viernes
    5: {
      scheduled: false,
      reserved: false,
      isEditing: false,
      data: null,
    }, // sabado
  },
  {
    schedule: '05:50pm-06:30pm',
    0: {
      scheduled: false,
      reserved: false,
      isEditing: false,
      data: null,
    }, // lunes
    1: {
      scheduled: false,
      reserved: false,
      isEditing: false,
      data: null,
    }, // martes
    2: {
      scheduled: false,
      reserved: false,
      isEditing: false,
      data: null,
    }, // miercoles
    3: {
      scheduled: false,
      reserved: false,
      isEditing: false,
      data: null,
    }, // jueves
    4: {
      scheduled: false,
      reserved: false,
      isEditing: false,
      data: null,
    }, // viernes
    5: {
      scheduled: false,
      reserved: false,
      isEditing: false,
      data: null,
    }, // sabado
  },
  {
    schedule: '06:30pm-07:10pm',
    0: {
      scheduled: false,
      reserved: false,
      isEditing: false,
      data: null,
    }, // lunes
    1: {
      scheduled: false,
      reserved: false,
      isEditing: false,
      data: null,
    }, // martes
    2: {
      scheduled: false,
      reserved: false,
      isEditing: false,
      data: null,
    }, // miercoles
    3: {
      scheduled: false,
      reserved: false,
      isEditing: false,
      data: null,
    }, // jueves
    4: {
      scheduled: false,
      reserved: false,
      isEditing: false,
      data: null,
    }, // viernes
    5: {
      scheduled: false,
      reserved: false,
      isEditing: false,
      data: null,
    }, // sabado
  },
  {
    schedule: '07:10pm-07:50pm',
    0: {
      scheduled: false,
      reserved: false,
      isEditing: false,
      data: null,
    }, // lunes
    1: {
      scheduled: false,
      reserved: false,
      isEditing: false,
      data: null,
    }, // martes
    2: {
      scheduled: false,
      reserved: false,
      isEditing: false,
      data: null,
    }, // miercoles
    3: {
      scheduled: false,
      reserved: false,
      isEditing: false,
      data: null,
    }, // jueves
    4: {
      scheduled: false,
      reserved: false,
      isEditing: false,
      data: null,
    }, // viernes
    5: {
      scheduled: false,
      reserved: false,
      isEditing: false,
      data: null,
    }, // sabado
  },
];

export const scheduleSevenDaysWeekStructure = [
  {
    schedule: '08:00am-09:00am',
    0: {
      scheduled: false,
      reserved: false,
      isEditing: false,
      data: null,
    }, // lunes
    1: {
      scheduled: false,
      reserved: false,
      isEditing: false,
      data: null,
    }, // martes
    2: {
      scheduled: false,
      reserved: false,
      isEditing: false,
      data: null,
    }, // miercoles
    3: {
      scheduled: false,
      reserved: false,
      isEditing: false,
      data: null,
    }, // jueves
    4: {
      scheduled: false,
      reserved: false,
      isEditing: false,
      data: null,
    }, // viernes
    5: {
      scheduled: false,
      reserved: false,
      isEditing: false,
      data: null,
    }, // sabado
    6: {
      scheduled: false,
      reserved: false,
      isEditing: false,
      data: null,
    }, // domingo
  },
  {
    schedule: '09:00am-10:00am',
    0: {
      scheduled: false,
      reserved: false,
      isEditing: false,
      data: null,
    }, // lunes
    1: {
      scheduled: false,
      reserved: false,
      isEditing: false,
      data: null,
    }, // martes
    2: {
      scheduled: false,
      reserved: false,
      isEditing: false,
      data: null,
    }, // miercoles
    3: {
      scheduled: false,
      reserved: false,
      isEditing: false,
      data: null,
    }, // jueves
    4: {
      scheduled: false,
      reserved: false,
      isEditing: false,
      data: null,
    }, // viernes
    5: {
      scheduled: false,
      reserved: false,
      isEditing: false,
      data: null,
    }, // sabado
    6: {
      scheduled: false,
      reserved: false,
      isEditing: false,
      data: null,
    }, // domingo
  },
  {
    schedule: '10:00am-11:00am',
    0: {
      scheduled: false,
      reserved: false,
      isEditing: false,
      data: null,
    }, // lunes
    1: {
      scheduled: false,
      reserved: false,
      isEditing: false,
      data: null,
    }, // martes
    2: {
      scheduled: false,
      reserved: false,
      isEditing: false,
      data: null,
    }, // miercoles
    3: {
      scheduled: false,
      reserved: false,
      isEditing: false,
      data: null,
    }, // jueves
    4: {
      scheduled: false,
      reserved: false,
      isEditing: false,
      data: null,
    }, // viernes
    5: {
      scheduled: false,
      reserved: false,
      isEditing: false,
      data: null,
    }, // sabado
    6: {
      scheduled: false,
      reserved: false,
      isEditing: false,
      data: null,
    }, // domingo
  },
  {
    schedule: '11:00am-12:00pm',
    0: {
      scheduled: false,
      reserved: false,
      isEditing: false,
      data: null,
    }, // lunes
    1: {
      scheduled: false,
      reserved: false,
      isEditing: false,
      data: null,
    }, // martes
    2: {
      scheduled: false,
      reserved: false,
      isEditing: false,
      data: null,
    }, // miercoles
    3: {
      scheduled: false,
      reserved: false,
      isEditing: false,
      data: null,
    }, // jueves
    4: {
      scheduled: false,
      reserved: false,
      isEditing: false,
      data: null,
    }, // viernes
    5: {
      scheduled: false,
      reserved: false,
      isEditing: false,
      data: null,
    }, // sabado
    6: {
      scheduled: false,
      reserved: false,
      isEditing: false,
      data: null,
    }, // domingo
  },
  {
    schedule: '12:00pm-01:00pm',
    0: {
      scheduled: false,
      reserved: false,
      isEditing: false,
      data: null,
    }, // lunes
    1: {
      scheduled: false,
      reserved: false,
      isEditing: false,
      data: null,
    }, // martes
    2: {
      scheduled: false,
      reserved: false,
      isEditing: false,
      data: null,
    }, // miercoles
    3: {
      scheduled: false,
      reserved: false,
      isEditing: false,
      data: null,
    }, // jueves
    4: {
      scheduled: false,
      reserved: false,
      isEditing: false,
      data: null,
    }, // viernes
    5: {
      scheduled: false,
      reserved: false,
      isEditing: false,
      data: null,
    }, // sabado
    6: {
      scheduled: false,
      reserved: false,
      isEditing: false,
      data: null,
    }, // domingo
  },
  {
    schedule: '01:00pm-02:00pm',
    0: {
      scheduled: false,
      reserved: false,
      isEditing: false,
      data: null,
    }, // lunes
    1: {
      scheduled: false,
      reserved: false,
      isEditing: false,
      data: null,
    }, // martes
    2: {
      scheduled: false,
      reserved: false,
      isEditing: false,
      data: null,
    }, // miercoles
    3: {
      scheduled: false,
      reserved: false,
      isEditing: false,
      data: null,
    }, // jueves
    4: {
      scheduled: false,
      reserved: false,
      isEditing: false,
      data: null,
    }, // viernes
    5: {
      scheduled: false,
      reserved: false,
      isEditing: false,
      data: null,
    }, // sabado
    6: {
      scheduled: false,
      reserved: false,
      isEditing: false,
      data: null,
    }, // domingo
  },
  {
    schedule: '02:00pm-03:00pm',
    0: {
      scheduled: false,
      reserved: false,
      isEditing: false,
      data: null,
    }, // lunes
    1: {
      scheduled: false,
      reserved: false,
      isEditing: false,
      data: null,
    }, // martes
    2: {
      scheduled: false,
      reserved: false,
      isEditing: false,
      data: null,
    }, // miercoles
    3: {
      scheduled: false,
      reserved: false,
      isEditing: false,
      data: null,
    }, // jueves
    4: {
      scheduled: false,
      reserved: false,
      isEditing: false,
      data: null,
    }, // viernes
    5: {
      scheduled: false,
      reserved: false,
      isEditing: false,
      data: null,
    }, // sabado
    6: {
      scheduled: false,
      reserved: false,
      isEditing: false,
      data: null,
    }, // domingo
  },
  {
    schedule: '03:00pm-04:00pm',
    0: {
      scheduled: false,
      reserved: false,
      isEditing: false,
      data: null,
    }, // lunes
    1: {
      scheduled: false,
      reserved: false,
      isEditing: false,
      data: null,
    }, // martes
    2: {
      scheduled: false,
      reserved: false,
      isEditing: false,
      data: null,
    }, // miercoles
    3: {
      scheduled: false,
      reserved: false,
      isEditing: false,
      data: null,
    }, // jueves
    4: {
      scheduled: false,
      reserved: false,
      isEditing: false,
      data: null,
    }, // viernes
    5: {
      scheduled: false,
      reserved: false,
      isEditing: false,
      data: null,
    }, // sabado
    6: {
      scheduled: false,
      reserved: false,
      isEditing: false,
      data: null,
    }, // domingo
  },
  {
    schedule: '04:00pm-05:00pm',
    0: {
      scheduled: false,
      reserved: false,
      isEditing: false,
      data: null,
    }, // lunes
    1: {
      scheduled: false,
      reserved: false,
      isEditing: false,
      data: null,
    }, // martes
    2: {
      scheduled: false,
      reserved: false,
      isEditing: false,
      data: null,
    }, // miercoles
    3: {
      scheduled: false,
      reserved: false,
      isEditing: false,
      data: null,
    }, // jueves
    4: {
      scheduled: false,
      reserved: false,
      isEditing: false,
      data: null,
    }, // viernes
    5: {
      scheduled: false,
      reserved: false,
      isEditing: false,
      data: null,
    }, // sabado
    6: {
      scheduled: false,
      reserved: false,
      isEditing: false,
      data: null,
    }, // domingo
  },
  {
    schedule: '05:00pm-06:00pm',
    0: {
      scheduled: false,
      reserved: false,
      isEditing: false,
      data: null,
    }, // lunes
    1: {
      scheduled: false,
      reserved: false,
      isEditing: false,
      data: null,
    }, // martes
    2: {
      scheduled: false,
      reserved: false,
      isEditing: false,
      data: null,
    }, // miercoles
    3: {
      scheduled: false,
      reserved: false,
      isEditing: false,
      data: null,
    }, // jueves
    4: {
      scheduled: false,
      reserved: false,
      isEditing: false,
      data: null,
    }, // viernes
    5: {
      scheduled: false,
      reserved: false,
      isEditing: false,
      data: null,
    }, // sabado
    6: {
      scheduled: false,
      reserved: false,
      isEditing: false,
      data: null,
    }, // domingo
  },
  {
    schedule: '06:00pm-07:00pm',
    0: {
      scheduled: false,
      reserved: false,
      isEditing: false,
      data: null,
    }, // lunes
    1: {
      scheduled: false,
      reserved: false,
      isEditing: false,
      data: null,
    }, // martes
    2: {
      scheduled: false,
      reserved: false,
      isEditing: false,
      data: null,
    }, // miercoles
    3: {
      scheduled: false,
      reserved: false,
      isEditing: false,
      data: null,
    }, // jueves
    4: {
      scheduled: false,
      reserved: false,
      isEditing: false,
      data: null,
    }, // viernes
    5: {
      scheduled: false,
      reserved: false,
      isEditing: false,
      data: null,
    }, // sabado
    6: {
      scheduled: false,
      reserved: false,
      isEditing: false,
      data: null,
    }, // domingo
  },
  {
    schedule: '07:00pm-08:00pm',
    0: {
      scheduled: false,
      reserved: false,
      isEditing: false,
      data: null,
    }, // lunes
    1: {
      scheduled: false,
      reserved: false,
      isEditing: false,
      data: null,
    }, // martes
    2: {
      scheduled: false,
      reserved: false,
      isEditing: false,
      data: null,
    }, // miercoles
    3: {
      scheduled: false,
      reserved: false,
      isEditing: false,
      data: null,
    }, // jueves
    4: {
      scheduled: false,
      reserved: false,
      isEditing: false,
      data: null,
    }, // viernes
    5: {
      scheduled: false,
      reserved: false,
      isEditing: false,
      data: null,
    }, // sabado
    6: {
      scheduled: false,
      reserved: false,
      isEditing: false,
      data: null,
    }, // domingo
  },
];

export const getCurrentWeek = (startDate, totalDays) => {
  const startDay = moment(startDate);
  const week = [
    {
      date: startDay.format('DD ddd').toUpperCase(),
      dayWeek: startDay.weekday(),
      dateFull: startDay.format('YYYY-MM-DD'),
    },
  ];
  for (let i = 0; i < totalDays; i++) {
    week.push({
      date: startDay.add(1, 'days').format('DD ddd').toUpperCase(),
      dayWeek: startDay.weekday(),
      dateFull: startDay.format('YYYY-MM-DD'),
    });
  }
  return week;
};

export const getDayNameByNumber = (dayNumber) => {
  let result = '';
  switch (dayNumber) {
    case 0:
      result = 'Lunes';
      break;
    case 1:
      result = 'Martes';
      break;
    case 2:
      result = 'Miércoles';
      break;
    case 3:
      result = 'Jueves';
      break;
    case 4:
      result = 'Viernes';
      break;
    case 5:
      result = 'Sábado';
      break;
    case 6:
      result = 'Domingo';
      break;
    default:
      break;
  }
  return result;
};

export const frecuencyOptions = [
  { name: '1 semana', value: '1' },
  { name: '2 semanas', value: '2' },
  { name: '3 semanas', value: '3' },
  { name: '1 mes', value: '4' },
  { name: '1 mes y medio', value: '6' },
  { name: '2 meses', value: '8' },
  { name: '2 meses  y medio', value: '10' },
  { name: '3 meses', value: '12' },
  { name: '3 meses y medio', value: '14' },
  { name: '4 meses', value: '16' },
  { name: '4 meses y medio', value: '18' },
  { name: '5 meses', value: '20' },
];
