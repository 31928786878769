import React, { useState, useContext } from 'react';
import PropTypes from 'prop-types';
import {
  Container, Row, Col, Button, Spinner,
} from 'reactstrap';
import Pagination from 'react-js-pagination';
import UserData from '../context/UserData';
import ModalCalculatorSG from './ModalCalculatorSG';
import '../styles/containerListItem.scss';

const PatientsContainerListItems = ({
  FilterComponent,
  handleDeleteRegister,
  handleDownloadRegister,
  handleFreeRegister,
  handleStopRegister,
  handleChangeRoleRegister,
  history,
  isLoading,
  activePage,
  handlePageChange,
  link,
  listData,
  RowComponent,
  subtitle,
  textButton,
  title,
  type,
  hideNavigation
}) => {
  const { userData } = useContext(UserData);
  const [modalIsOpen, setModalIsOpen] = useState(false);

  const toggleModal = () => setModalIsOpen((prevState) => !prevState);
  return (
    <>
      <Container fluid className="container-data">
        <Row>
          <Col
            xs="12"
            md={FilterComponent ? 3 : 8}
            lg={FilterComponent ? 4 : 8}
            xl={FilterComponent ? 3 : 8}
          >
            <p className="title">{title}</p>
          </Col>
          {FilterComponent && (
            <Col xs="12" md="6" lg="5" xl="6">
              {FilterComponent()}
            </Col>
          )}
          <Col xs="6" md="3" lg="3" className="btn-column">
            {type === 'patient' && (
              <Button
                className="btn-custom btn-calculator"
                color=""
                onClick={toggleModal}
              >
                Calcular SG
              </Button>
            )}
            {userData
              ? userData.role === 'Administrador' && (
                <Button
                  className="btn-custom"
                  color=""
                  onClick={() => history.push(link)}
                >
                  {textButton}
                </Button>
              )
              : null}
          </Col>
          <Col xs="6" md="12" className="subtitle-column">
            <p className="subtitle">{subtitle}</p>
          </Col>
        </Row>
        <Row>
          <Col xs="12" className="content-column">
            {isLoading ? (
              <Spinner />
            ) : listData.length === 0 ? (
              <h3>No hay resultados</h3>
            ) : (
              listData.map((item) => {
                return (
                  <RowComponent
                    key={item.id}
                    item={item}
                    history={history}
                    handleDeleteRegister={handleDeleteRegister}
                    handleDownloadRegister={handleDownloadRegister}
                    handleFreeRegister={handleFreeRegister}
                    handleStopRegister={handleStopRegister}
                    handleChangeRoleRegister={handleChangeRoleRegister}
                  />
                );
              })
            )}
          </Col>
          <Col xs="12" className="pagination-column">
            <Pagination
              totalItemsCount={listData.length < 15 && activePage == 1 ? listData.length : (15 * activePage) + 15}
              activePage={activePage}
              itemsCountPerPage={15}
              pageRangeDisplayed={1}
              onChange={handlePageChange}
              itemClass="item"
              itemClassFirst="control"
              itemClassPrev="control"
              itemClassNext="control"
              itemClassLast="control"
              linkClass="link"
              hideFirstLastPages
              hideNavigation={hideNavigation || listData.length < 15}
            />
          </Col>
        </Row>
      </Container>
      <ModalCalculatorSG show={modalIsOpen} close={toggleModal} />
    </>
  );
};

PatientsContainerListItems.defaultProps = {
  FilterComponent: null,
  handleDeleteRegister: () => null,
  handleDownloadRegister: () => null,
  handleFreeRegister: () => null,
  handleStopRegister: () => null,
  handleChangeRoleRegister: () => null,
  isLoading: false,
  listData: [],
};

PatientsContainerListItems.propTypes = {
  FilterComponent: PropTypes.func,
  handleDeleteRegister: PropTypes.func,
  handleDownloadRegister: PropTypes.func,
  handleFreeRegister: PropTypes.func,
  handleStopRegister: PropTypes.func,
  handleChangeRoleRegister: PropTypes.func,
  history: PropTypes.object.isRequired,
  isLoading: PropTypes.bool,
  activePage: PropTypes.number,
  handlePageChange: PropTypes.func,
  link: PropTypes.string.isRequired,
  listData: PropTypes.array,
  RowComponent: PropTypes.oneOfType([PropTypes.element, PropTypes.func])
    .isRequired,
  subtitle: PropTypes.string.isRequired,
  textButton: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  type: PropTypes.oneOf(['patient', 'user']).isRequired,
};
export default PatientsContainerListItems;
