/* eslint-disable no-else-return */
/* eslint-disable max-len */
import moment from 'moment';
import 'moment/locale/es';

export const getCorrectedWeeksOfGestation = (birthDate, currentDate, sg) => {
  const fechaNacimiento = moment(birthDate, 'YYYY-MM-DD');
  const fechaActual = moment(currentDate, 'YYYY-MM-DD');
  let semanasGestacion = parseInt(sg);

  // Ajustar semanas de gestación según reglas
  if (semanasGestacion > 40) {
    semanasGestacion = 40;
  }

  let diasDeNacido = fechaActual.diff(fechaNacimiento, 'days');
  const edadCronologica = moment.duration(fechaActual.diff(fechaNacimiento));
  const aniosCronologicos = edadCronologica.years();
  let mesesCronologicos = edadCronologica.months();
  let diasCronologicos = edadCronologica.days();

  let semanasFaltaban = 40 - semanasGestacion;
  // const diasFaltantes = semanasFaltaban * 7;

  // Calcular el tiempo faltante en meses y días
  let mesesFaltantes = Math.floor(semanasFaltaban / 4);
  console.log('🚀 ~ getCorrectedWeeksOfGestation ~ mesesFaltantes:', mesesFaltantes)
  let diasRestantesFaltantes = (semanasFaltaban % 4) * 7;

  let edadCorregidaEnDias = 0;
  let diasCorregidos = diasCronologicos - diasRestantesFaltantes;
  let mesesCorregidos;
  let edadCorregidaEnSemanas;
  let diasRestantesCorregidos;
  let aniosCorregidos;
  let mesesRestados = 0;

  if (diasRestantesFaltantes > diasCronologicos) {
    mesesRestados = 1;
    let diasCronologicos2 = ((mesesCronologicos * 30) + diasCronologicos) - diasRestantesFaltantes;
    mesesCorregidos = Math.floor(diasCronologicos2 / 30);
    console.log('🚀 ~ getCorrectedWeeksOfGestation ~ mesesCorregidos:', mesesCorregidos)
    diasCorregidos = diasCronologicos2 % 30 
  }
  
  if (mesesFaltantes <= mesesCronologicos) {
    mesesCorregidos = mesesCronologicos - mesesFaltantes - mesesRestados;
    edadCorregidaEnDias += mesesCorregidos * 30;
    edadCorregidaEnDias += diasCorregidos;
  
    edadCorregidaEnSemanas = Math.floor(edadCorregidaEnDias / 7);
    diasRestantesCorregidos = edadCorregidaEnDias % 7;
  
    // Calcular edad corregida exacta en años, meses y días
    const edadCorregidaExacta = moment.duration(edadCorregidaEnDias, 'days');
    aniosCorregidos = edadCorregidaExacta.years();

  } else {
    diasCorregidos = 0;
    mesesCorregidos = 0;
    aniosCorregidos = 0;
    diasRestantesCorregidos = 0;
    const semanas = Math.floor(diasCronologicos / 7) + mesesCronologicos * 4; 
    edadCorregidaEnSemanas = semanas + semanasGestacion;
  }

  // const mesesCorregidos = Math.floor(edadCorregidaExacta.asDays() / 30);
  


  // if (mesesCronologicos > mesesFaltantes) {
  //   mesesCorregidos = mesesCronologicos - mesesFaltantes;
  //   diasCorregidos = diasCronologicos - diasRestantesFaltantes;
  // } else {
  //   const diasFaltantes = mesesFaltantes * 7
  //   mesesCorregidos = semanasGestacion;
  //   diasCorregidos = 0;
  // }

  // const diasCorregidos = edadCorregidaExacta.asDays() % 30;
  // const diasCorregidos = diasCronologicos - diasRestantesFaltantes;

  const results = {
    bornDays: diasDeNacido,
    chronoAge: {
      years: aniosCronologicos,
      months: mesesCronologicos,
      days: diasCronologicos,
    },
    leftTime: {
      months: mesesFaltantes,
      days: diasRestantesFaltantes,
    },
    correctedAge: {
      years: aniosCorregidos,
      months: mesesCorregidos,
      days: diasCorregidos,
    },
    correctedAgeInWeeks: {
      weeks: edadCorregidaEnSemanas,
      days: diasRestantesCorregidos,
    },
  };

  return results;
};

export const flipDate = (date) => date.split('-').reverse().join('-');

// export const getCurrentDateFull = () => moment().format('DD-MM-YYYY hh:mm:ss');

// export const getCurrentDate = () => `${moment().year()}-${moment().month() + 1 < 10 ? 0 : ''}${moment().month() + 1
//   }-${moment().date() < 10 ? `0${moment().date()}` : moment().date()}`;

// export const getCurrentYear = () => moment(getCurrentDate()).year();

// export const getCurrentMonthNumber = () => `${moment(getCurrentDate()).month() + 1 < 10 ? 0 : ''}${moment(getCurrentDate()).month() + 1
//   }`;

// export const getCurrentMonthYearText = () => moment().format('MMMM YYYY').toLocaleUpperCase();

// export const getDayNumberByDate = (date) => moment(date).isoWeekday() - 1;

// export const getDayNameByDate = (date) => moment(date).locale('es').format('dddd');

export const getMovementType = (correctedAgeWeeks) => {
  const totalWeeks = correctedAgeWeeks?.weeks + correctedAgeWeeks?.days / 7; // Math.trunc
  if (totalWeeks >= 26 && totalWeeks < 38) {
    if (totalWeeks <= 31) return 'Mov. prematuro (N, PR)';
    if (totalWeeks <= 35) return 'Mov. prematuro (N, PR, CH)';
    return 'Mov. prematuro (N, PR, CS)';
  } else if ((totalWeeks >= 38 && totalWeeks <= 40) || totalWeeks < 9) {
    return 'Mov. writhing (N, PR, CS)';
  } else if (totalWeeks >= 9 && totalWeeks <= 22) {
    if (totalWeeks <= 15) return 'Fidgety (F, CS, AF, F-)';
    return 'Fidgety (CS, AF, F-)';
  } else if (totalWeeks >= 23 && totalWeeks < 25) {
    return 'Mov. Antigrav.';
  } else {
    return '';
  }
};

export const calculatePercentage = (score, total) => {
  const resultado = (score / total) * 100;
  return Math.round(resultado);
};

export const getGMOSPercentage = (score) => {
  const total = 38;
  return calculatePercentage(score, total);
};

export const getMOSPercentage = (score) => {
  const total = 28;
  return calculatePercentage(score, total);
};

export const getEcoWeeksPrePost = (ecoWeeks) => {
  let weeksNumber;
  let type = '';
  if (ecoWeeks?.includes(' s pret')) {
    weeksNumber = ecoWeeks.split(' s ')[0].split(' ');
    type = 'pret';
  } else if (ecoWeeks?.includes(' s post')) {
    weeksNumber = ecoWeeks?.split(' s ')[0].split(' ');
    type = 'post';
  } else if (ecoWeeks?.split(', ')?.length === 2) {
    weeksNumber = ecoWeeks?.split(', ')[0].split(' ');
  }
  return {
    age: weeksNumber ? Number(weeksNumber[0]) : null,
    type,
  };
};

function differenceInDays(date1, date2) {
  const timestamp1 = date1.getTime();
  const timestamp2 = date2.getTime();

  const differenceInMilliseconds = Math.abs(timestamp2 - timestamp1);

  const differenceInDays = Math.ceil(differenceInMilliseconds / (1000 * 3600 * 24));

  return differenceInDays;
}

export const getCurrentDateFull = () => moment().format('DD-MM-YYYY hh:mm:ss');

export const getCurrentDate = () => moment().format('YYYY-MM-DD');

export const getCurrentYear = () => moment().year();

export const getCurrentMonthNumber = () => moment().month() + 1;

export const getCurrentMonthYearText = () => moment().format('MMMM YYYY').toUpperCase();

export const getDayNumberByDate = (date) => moment(date).isoWeekday() - 1;

export const getDayNameByDate = (date) => moment(date).locale('es').format('dddd');