// // estatus -> Indica el color: 0 = negro, 1 = verde, 2 = rojo
export const HammerSmithType = {
  id: '',
  patientId: '', // Cuando es del formulario de valoración
  neuroMonitoringId: '', // Cuando es del formulario de neuroseguimiento
  neuroMonitoringPatientId: '', // Cuando es del formulario de neuroseguimiento
  doctorIdCreated: '',
  timestamp: '',
  createdAt: '',
  updatedAt: '',
  resultado: 0,
  resultadoAsimetria: {
    izquierda: 0,
    derecha: 0,
  },
  resultadosParciales: {
    extremidadesSuperiores: 0,
    extremidadesInferiores: 0,
    cuelloTronco: 0,
    secuencia: 0,
  },
  cuestionario: {
    // Preguntas
    paresCraneales: {
      titulo: 'Evaluación de la función de los pares craneales', // Título lateral
      valorSecciones: 0,
      valorSeccionesId: 0,
      secciones: [
        {
          subtitulo: 'Apariencia facial',
          valorIndicador: 0,
          valorAsimetria: '',
          indicadores: [
            // Preguntas
            {
              indicador:
                '<span class="text-blod">Sonríe y/o reacciona</span> a los estímulos cerrando los ojos y haciendo muecas',
              // estatus: 1,
              valor: 4,
              indicadorId: 1,
              iconId: '',
            },
            {
              indicador: '',
              // estatus: 1,
              valor: 3.5,
              indicadorId: 2,
              iconId: '',
            },
            {
              indicador: '',
              // estatus: 2,
              valor: 3,
              indicadorId: 3,
            },
            {
              indicador: '',
              // estatus: 1,
              valor: 2.5,
              indicadorId: 4,
              iconId: '',
            },
            {
              indicador:
                'Cierra los ojos pero no con firmeza <span class="text-blod">Pobre expresión facial</span>',
              // estatus: 2,
              valor: 2,
              indicadorId: 5,
              iconId: '',
            },
            {
              indicador: '',
              // estatus: 1,
              valor: 1.5,
              indicadorId: 6,
              iconId: '',
            },
            {
              indicador: '<span class="text-blod">Inexpresiva No reacciona a los estímulos</span>',
              // estatus: 2,
              valor: 1,
              indicadorId: 7,
              iconId: '',
            },
          ],
          asimetrias: [
            {
              asimetria: 'I',
              // estatus: "1",
              valor: 'I',
              asimetriaId: 1,
            },
            {
              asimetria: 'D',
              // estatus: "1",
              valor: 'D',
              asimetriaId: 2,
            },
          ],
        },
        {
          subtitulo: 'Movimientos Oculares',
          valorIndicador: 0,
          valorAsimetria: '',
          indicadores: [
            {
              indicador: 'Movimientos oculares <span class="text-blod">conjugados normales</span>',
              // estatus: 0,
              valor: 4,
              indicadorId: 1,
              iconId: '',
            },
            {
              indicador: '',
              // estatus: 1,
              valor: 3.5,
              indicadorId: 2,
              iconId: '',
            },
            {
              indicador: '',
              // estatus: 2,
              valor: 3,
              indicadorId: 3,
              iconId: '',
            },
            {
              indicador: '',
              // estatus: 1,
              valor: 2.5,
              indicadorId: 4,
              iconId: '',
            },
            {
              indicador:
                '<span class="text-blod">Desviación intermitente</span> o movimiento anormales <span class="text-blod">itermitentes</span>',
              // estatus: 2,
              valor: 2,
              indicadorId: 5,
              iconId: '',
            },
            {
              indicador: '',
              // estatus: 1,
              valor: 1.5,
              indicadorId: 6,
              iconId: '',
            },
            {
              indicador:
                'Desviación <span class="text-blod">permanente</span> o movimientos anormales <span class="text-blod">continuos</span>',
              // estatus: 2,
              valor: 1,
              indicadorId: 7,
              iconId: '',
            },
          ],
          asimetrias: [
            {
              asimetria: 'I',
              // estatus: "I",
              valor: 'I',
              asimetriaId: 1,
            },
            {
              asimetria: 'D',
              // estatus: "D",
              valor: 'D',
              asimetriaId: 2,
            },
          ],
        },
        {
          subtitulo: 'Respuesta Auditíva',
          valorIndicador: 0,
          valorAsimetria: '',
          indicadores: [
            {
              indicador: '<span class="text-blod">Responde</span> al estímulo desde ambos lados',
              // estatus: 1,
              valor: 4,
              indicadorId: 1,
            },
            {
              indicador: '',
              // estatus: 1,
              valor: 3.5,
              indicadorId: 2,
              iconId: '',
            },
            {
              indicador: '',
              // estatus: 1,
              valor: 3,
              indicadorId: 3,
              iconId: '',
            },
            {
              indicador: '',
              // estatus: 1,
              valor: 2.5,
              indicadorId: 4,
              iconId: '',
            },
            {
              indicador: 'Arco <span class="text-blod">incompleto</span> o <span class="text-blod">asimétrico</span>',
              // estatus: 2,
              valor: 2,
              indicadorId: 5,
              iconId: '',
            },
            {
              indicador: '',
              // estatus: 1,
              valor: 1.5,
              indicadorId: 6,
              iconId: '',
            },
            {
              indicador: '<span class="text-blod">No</span> sigue',
              // estatus: 2,
              valor: 1,
              indicadorId: 7,
              iconId: '',
            },
          ],
          asimetrias: [
            {
              asimetria: 'I',
              // estatus: "I",
              valor: 'I',
              asimetriaId: 1,
            },
            {
              asimetria: 'D',
              // estatus: "D",
              valor: 'D',
              asimetriaId: 2,
            },
          ],
        },
        {
          subtitulo: 'Respuesta Visual',
          valorIndicador: 0,
          valorAsimetria: '',
          indicadores: [
            {
              indicador: 'Sigue el objeto en un arco <span class="text-blod">completo</span>',
              // estatus: 1,
              valor: 4,
              indicadorId: 1,
              iconId: '',
            },
            {
              indicador: '',
              // estatus: 1,
              valor: 3.5,
              indicadorId: 2,
              iconId: '',
            },
            {
              indicador: '',
              // estatus: 1,
              valor: 3,
              indicadorId: 3,
              iconId: '',
            },
            {
              indicador: '',
              // estatus: 1,
              valor: 2.5,
              indicadorId: 4,
              iconId: '',
            },
            {
              indicador:
                'Sigue el objeto en un arco <span class="text-blod">incompleto</span>, o de forma <span class="text-blod">asimétrica</span>',
              // estatus: 1,
              valor: 2,
              indicadorId: 5,
              iconId: '',
            },
            {
              indicador: '',
              // estatus: 1,
              valor: 1.5,
              indicadorId: 6,
              iconId: '',
            },
            {
              indicador: '<span class="text-blod">No</span> sigue el objeto',
              // estatus: 1,
              valor: 1,
              indicadorId: 7,
              iconId: '',
            },
          ],
          asimetrias: [
            {
              asimetria: 'I',
              // estatus: "I",
              valor: 'I',
              asimetriaId: 1,
            },
            {
              asimetria: 'D',
              // estatus: "D",
              valor: 'D',
              asimetriaId: 2,
            },
          ],
        },
        {
          subtitulo: 'Succión / Deglución',
          valorIndicador: 0,
          valorAsimetria: '',
          indicadores: [
            {
              indicador: '<span class="text-blod">Buena</span> succión y deglución',
              // estatus: 1,
              valor: 4,
              indicadorId: 1,
              iconId: '',
            },
            {
              indicador: '',
              // estatus: 1,
              valor: 3.5,
              indicadorId: 2,
              iconId: '',
            },
            {
              indicador: '',
              // estatus: 1,
              valor: 3,
              indicadorId: 3,
              iconId: '',
            },
            {
              indicador: '',
              // estatus: 1,
              valor: 2.5,
              indicadorId: 4,
              iconId: '',
            },
            {
              indicador: '<span class="text-blod">Pobre</span> succión y/o deglución',
              // estatus: 2,
              valor: 2,
              indicadorId: 5,
              iconId: '',
            },
            {
              indicador: '',
              // estatus: 1,
              valor: 1.5,
              indicadorId: 6,
              iconId: '',
            },
            {
              indicador: '<span class="text-blod">No</span> reflejo de succión, imposible deglutir',
              // estatus: 2,
              valor: 1,
              indicadorId: 7,
              iconId: '',
            },
          ],
          asimetrias: [
            {
              asimetria: 'I',
              // estatus: "I",
              valor: 'I',
              asimetriaId: 1,
            },
            {
              asimetria: 'D',
              // estatus: "D",
              valor: 'D',
              asimetriaId: 2,
            },
          ],
        },
      ],
    },
    movimientos: {
      titulo: 'Evalución de los movimientos', // Título lateral
      valorSecciones: 0,
      valorSeccionesId: 0,
      secciones: [
        {
          subtitulo: 'Cantidad',
          valorIndicador: 0,
          valorAsimetria: '',
          indicadores: [
            // Preguntas
            {
              indicador: 'Normales',
              // estatus: 1,
              valor: 4,
              indicadorId: 1,
              iconId: '',
            },
            {
              indicador: '',
              // estatus: 1,
              valor: 3.5,
              indicadorId: 2,
              iconId: '',
            },
            {
              indicador: '',
              // estatus: 2,
              valor: 3,
              indicadorId: 3,
              iconId: '',
            },
            {
              indicador: '',
              // estatus: 1,
              valor: 2.5,
              indicadorId: 4,
              iconId: '',
            },
            {
              indicador: '<span class="text-blod">Excesivos o lentos</span> (perezosos)',
              // estatus: 2,
              valor: 2,
              indicadorId: 5,
              iconId: '',
            },
            {
              indicador: '',
              // estatus: 1,
              valor: 1.5,
              indicadorId: 6,
              iconId: '',
            },
            {
              indicador: 'Muy <span class="text-blod">escasos</span> o ausentes',
              // estatus: 2,
              valor: 1,
              indicadorId: 7,
              iconId: '',
            },
          ],
          asimetrias: [
            {
              asimetria: 'I',
              // estatus: "I",
              valor: 'I',
              asimetriaId: 1,
            },
            {
              asimetria: 'D',
              // estatus: "D",
              valor: 'D',
              asimetriaId: 2,
            },
          ],
        },
        {
          subtitulo: 'Calidad',
          valorIndicador: 0,
          valorAsimetria: '',
          indicadores: [
            {
              indicador: 'Fluidos, suaves y alternantes',
              // estatus: 0,
              valor: 4,
              indicadorId: 1,
              iconId: '',
            },
            {
              indicador: '',
              // estatus: 1,
              valor: 3.5,
              indicadorId: 2,
              iconId: '',
            },
            {
              indicador: '',
              // estatus: 2,
              valor: 3,
              indicadorId: 3,
              iconId: '',
            },
            {
              indicador: '',
              // estatus: 1,
              valor: 2.5,
              indicadorId: 4,
              iconId: '',
            },
            {
              indicador: '<span class="text-blod">Bruscos, Entrecortados, Leve temblor</span>',
              // estatus: 2,
              valor: 2,
              indicadorId: 5,
              iconId: '',
            },
            {
              indicador: '',
              // estatus: 1,
              valor: 1.5,
              indicadorId: 6,
              iconId: '',
            },
            {
              indicador:
                'Bruscos, rígidos y sincrónicos. Espasmos en extensión. Atetoides, Atáxicos, Temblores intensos, Espasmos miociónicos. Distónicos',
              // estatus: 2,
              valor: 1,
              indicadorId: 7,
              iconId: '',
            },
          ],
          asimetrias: [
            {
              asimetria: 'I',
              // estatus: "I",
              valor: 'I',
              asimetriaId: 1,
            },
            {
              asimetria: 'D',
              // estatus: "D",
              valor: 'D',
              asimetriaId: 2,
            },
          ],
        },
      ],
    },
    tono: {
      titulo: 'Evaluación del tono', // Título lateral
      valorSecciones: 0,
      valorSeccionesId: 0,
      secciones: [
        {
          subtitulo: 'Bufanda',
          valorIndicador: 0,
          valorAsimetria: '',
          indicadores: [
            // Preguntas
            {
              indicador: 'Rango',
              // estatus: 1,
              valor: 4,
              indicadorId: 1,
              iconId: 'signo-de-la-bufanda-1',
              iconsIds: ['signo-de-la-bufanda-1-L', 'signo-de-la-bufanda-1-R'],
              DI: 2,
            },
            {
              indicador: '',
              // estatus: 1,
              valor: 3.5,
              indicadorId: 2,
              iconId: '',
            },
            {
              indicador: '',
              // estatus: 2,
              valor: 3,
              indicadorId: 3,
              iconId: '',
            },
            {
              indicador: '',
              // estatus: 1,
              valor: 2.5,
              indicadorId: 4,
              iconId: '',
            },
            {
              indicador: '',
              // estatus: 2,
              valor: 2,
              indicadorId: 5,
              iconId: 'signo-de-la-bufanda-2',
              DI: 1,
            },
            {
              indicador: '',
              // estatus: 1,
              valor: 1.5,
              indicadorId: 6,
              iconId: '',
            },
            {
              indicador: 'D I escasos o ausentes',
              // estatus: 2,
              valor: 1,
              indicadorId: 7,
              iconId: 'signo-de-la-bufanda-3',
              iconsIds: ['signo-de-la-bufanda-3-L', 'signo-de-la-bufanda-3-R'],
              DI: 2,
            },
          ],
          asimetrias: [
            {
              asimetria: 'I',
              // estatus: "I",
              valor: 'I',
              asimetriaId: 1,
            },
            {
              asimetria: 'D',
              // estatus: "D",
              valor: 'D',
              asimetriaId: 2,
            },
          ],
        },
        {
          subtitulo: 'Elevación pasiva del hombro',
          valorIndicador: 0,
          valorAsimetria: '',
          indicadores: [
            {
              indicador: 'Resistencia <span class="text-blod">superable</span>',
              // estatus: 0,
              valor: 4,
              indicadorId: 1,
              iconId: 'elevacion-pasiva-del-hombro-1',
              DI: 1,
            },
            {
              indicador: '',
              // estatus: 1,
              valor: 3.5,
              indicadorId: 2,
              iconId: '',
            },
            {
              indicador: '<span class="text-blod">Dificultad</span><br /> para vencer la resistencia',
              // estatus: 2,
              valor: 3,
              indicadorId: 3,
              iconId: '',
            },
            {
              indicador: '',
              // estatus: 1,
              valor: 2.5,
              indicadorId: 4,
              iconId: '',
            },
            {
              indicador: '<span class="text-blod">No</span> existe resistencia',
              // estatus: 2,
              valor: 2,
              indicadorId: 5,
              iconId: 'elevacion-pasiva-del-hombro-2',
              DI: 1,
            },
            {
              indicador: '',
              // estatus: 1,
              valor: 1.5,
              indicadorId: 6,
              iconId: '',
            },
            {
              indicador: 'Resistencia <span class="text-blod">no superable</span>',
              // estatus: 2,
              valor: 1,
              indicadorId: 7,
              iconId: 'elevacion-pasiva-del-hombro-3',
              DI: 1,
            },
          ],
          asimetrias: [
            {
              asimetria: 'I',
              // estatus: "I",
              valor: 'I',
              asimetriaId: 1,
            },
            {
              asimetria: 'D',
              // estatus: "D",
              valor: 'D',
              asimetriaId: 2,
            },
          ],
        },
        {
          subtitulo: 'Pronación / Supinación',
          valorIndicador: 0,
          valorAsimetria: '',
          indicadores: [
            {
              indicador:
                'Pronación y supinación <span class="text-blod">completas</span>, no existe resistencia',
              // estatus: 0,
              valor: 4,
              indicadorId: 1,
              iconId: '',
            },
            {
              indicador: '',
              // estatus: 1,
              valor: 3.5,
              indicadorId: 2,
              iconId: '',
            },
            {
              indicador: '',
              // estatus: 2,
              valor: 3,
              indicadorId: 3,
              iconId: '',
            },
            {
              indicador: '',
              // estatus: 1,
              valor: 2.5,
              indicadorId: 4,
              iconId: '',
            },
            {
              indicador:
                'Pronación y supinación <span class="text-blod">completas. Existe resistencia, pero se puede vencer</span>',
              // estatus: 2,
              valor: 2,
              indicadorId: 5,
              iconId: '',
            },
            {
              indicador: '',
              // estatus: 1,
              valor: 1.5,
              indicadorId: 6,
              iconId: '',
            },
            {
              indicador:
                '<span class="text-blod">No</span> pronación / supinación completa <span class="text-blod">Excesiva resistencia</span>',
              // estatus: 2,
              valor: 1,
              indicadorId: 7,
              iconId: '',
            },
          ],
          asimetrias: [
            {
              asimetria: 'I',
              // estatus: "I",
              valor: 'I',
              asimetriaId: 1,
            },
            {
              asimetria: 'D',
              // estatus: "D",
              valor: 'D',
              asimetriaId: 2,
            },
          ],
        },
        {
          subtitulo: 'Aductores',
          valorIndicador: 0,
          valorAsimetria: '',
          indicadores: [
            {
              indicador: 'Rango 150º - 80ª',
              // estatus: 0,
              valor: 4,
              indicadorId: 1,
              iconId: 'aductores-de-cadera-1',
              iconsIds: ['aductores-de-cadera-1-L', 'aductores-de-cadera-1-R'],
              DI: 2,
            },
            {
              indicador: '',
              // estatus: 1,
              valor: 3.5,
              indicadorId: 2,
              iconId: '',
            },
            {
              indicador: '150º - 160º',
              // estatus: 2,
              valor: 3,
              indicadorId: 3,
              iconId: 'aductores-de-cadera-2',
              DI: 1,
            },
            {
              indicador: '',
              // estatus: 1,
              valor: 2.5,
              indicadorId: 4,
              iconId: '',
            },
            {
              indicador: '< 170º',
              // estatus: 2,
              valor: 2,
              indicadorId: 5,
              iconId: 'aductores-de-cadera-3',
              DI: 1,
            },
            {
              indicador: '',
              // estatus: 1,
              valor: 1.5,
              indicadorId: 6,
              iconId: '',
            },
            {
              indicador: '< 80º',
              // estatus: 2,
              valor: 1,
              indicadorId: 7,
              iconId: 'aductores-de-cadera-4',
              DI: 1,
            },
          ],
          asimetrias: [
            {
              asimetria: 'I',
              // estatus: "I",
              valor: 'I',
              asimetriaId: 1,
            },
            {
              asimetria: 'D',
              // estatus: "D",
              valor: 'D',
              asimetriaId: 2,
            },
          ],
        },
        {
          subtitulo: 'Ángulo poplítero',
          valorIndicador: 0,
          valorAsimetria: '',
          indicadores: [
            {
              indicador: 'Rango 150º - 100º',
              // estatus: 0,
              valor: 4,
              indicadorId: 1,
              iconId: 'angulo-popitleo-1',
              iconsIds: ['angulo-popitleo-1-L', 'angulo-popitleo-1-R'],
              DI: 2,
            },
            {
              indicador: '',
              // estatus: 1,
              valor: 3.5,
              indicadorId: 2,
              iconId: '',
            },
            {
              indicador: '150º - 160º',
              // estatus: 2,
              valor: 3,
              indicadorId: 3,
              iconId: 'angulo-popitleo-2',
              DI: 1,
            },
            {
              indicador: '',
              // estatus: 1,
              valor: 2.5,
              indicadorId: 4,
              iconId: '',
            },
            {
              indicador: '-90º o > 170º',
              // estatus: 2,
              valor: 2,
              indicadorId: 5,
              iconId: 'angulo-popitleo-3',
              iconsIds: ['angulo-popitleo-3', 'angulo-popitleo-3-1'],
              DI: 2,
            },
            {
              indicador: '',
              // estatus: 1,
              valor: 1.5,
              indicadorId: 6,
              iconId: '',
            },
            {
              indicador: '< 80º',
              // estatus: 2,
              valor: 1,
              indicadorId: 7,
              iconId: 'angulo-popitleo-4',
              DI: 1,
            },
          ],
          asimetrias: [
            {
              asimetria: 'I',
              // estatus: "I",
              valor: 'I',
              asimetriaId: 1,
            },
            {
              asimetria: 'D',
              // estatus: "D",
              valor: 'D',
              asimetriaId: 2,
            },
          ],
        },
        {
          subtitulo: 'Dorsiflexión de tobillo',
          valorIndicador: 0,
          valorAsimetria: '',
          indicadores: [
            {
              indicador: 'Rango 30º - 85º',
              // estatus: 0,
              valor: 4,
              indicadorId: 1,
              iconId: 'dorsiflexión-de-tobillo-1',
              iconsIds: ['dorsiflexión-de-tobillo-1-L', 'dorsiflexión-de-tobillo-1-R'],
              DI: 2,
            },
            {
              indicador: '',
              // estatus: 1,
              valor: 3.5,
              indicadorId: 2,
              iconId: '',
            },
            {
              indicador: '20º - 30º',
              // estatus: 2,
              valor: 3,
              indicadorId: 3,
              iconId: 'dorsiflexión-de-tobillo-2-new',
              DI: 1,
            },
            {
              indicador: '',
              // estatus: 1,
              valor: 2.5,
              indicadorId: 4,
              iconId: '',
            },
            {
              indicador: '<20º o 90º',
              // estatus: 2,
              valor: 2,
              indicadorId: 5,
              iconId: 'dorsiflexión-de-tobillo-3',
              iconsIds: ['dorsiflexión-de-tobillo-3-L', 'dorsiflexión-de-tobillo-3-R'],
              DI: 2,
            },
            {
              indicador: '',
              // estatus: 1,
              valor: 1.5,
              indicadorId: 6,
              iconId: '',
            },
            {
              indicador: '>90º',
              // estatus: 2,
              valor: 1,
              indicadorId: 7,
              iconId: 'dorsiflexión-de-tobillo-4-new',
              DI: 1,
            },
          ],
          asimetrias: [
            {
              asimetria: 'I',
              // estatus: "I",
              valor: 'I',
              asimetriaId: 1,
            },
            {
              asimetria: 'D',
              // estatus: "D",
              valor: 'D',
              asimetriaId: 2,
            },
          ],
        },
        {
          subtitulo: 'Pull to sit',
          valorIndicador: 0,
          valorAsimetria: '',
          indicadores: [
            {
              indicador: '',
              // estatus: 0,
              valor: 4,
              indicadorId: 1,
              iconId: 'pull-to-sit-1',
              iconsIds: ['pull-to-sit-1-L', 'pull-to-sit-1-R']
            },
            {
              indicador: '',
              // estatus: 1,
              valor: 3.5,
              indicadorId: 2,
              iconId: '',
            },
            {
              indicador: '',
              // estatus: 2,
              valor: 3,
              indicadorId: 3,
              iconId: '',
            },
            {
              indicador: '',
              // estatus: 1,
              valor: 2.5,
              indicadorId: 4,
              iconId: '',
            },
            {
              indicador: '',
              // estatus: 2,
              valor: 2,
              indicadorId: 5,
              iconId: 'pull-to-sit-2',
            },
            {
              indicador: '',
              // estatus: 1,
              valor: 1.5,
              indicadorId: 6,
              iconId: '',
            },
            {
              indicador: '',
              // estatus: 2,
              valor: 1,
              indicadorId: 7,
              iconId: 'pull-to-sit-3',
            },
          ],
          asimetrias: [
            {
              asimetria: 'I',
              // estatus: "I",
              valor: 'I',
              asimetriaId: 1,
            },
            {
              asimetria: 'D',
              // estatus: "D",
              valor: 'D',
              asimetriaId: 2,
            },
          ],
        },
        {
          subtitulo: 'Suspensión ventral',
          valorIndicador: 0,
          valorAsimetria: '',
          indicadores: [
            {
              indicador: '',
              // estatus: 0,
              valor: 4,
              indicadorId: 1,
              iconId: 'suspension-ventral-1',
              iconsIds: ['suspension-ventral-1-L', 'suspension-ventral-1-R']
            },
            {
              indicador: '',
              // estatus: 1,
              valor: 3.5,
              indicadorId: 2,
              iconId: '',
            },
            {
              indicador: '',
              // estatus: 2,
              valor: 3,
              indicadorId: 3,
              iconId: '',
            },
            {
              indicador: '',
              // estatus: 1,
              valor: 2.5,
              indicadorId: 4,
              iconId: '',
            },
            {
              indicador: '',
              // estatus: 2,
              valor: 2,
              indicadorId: 5,
              iconId: 'suspension-ventral-2',
            },
            {
              indicador: '',
              // estatus: 1,
              valor: 1.5,
              indicadorId: 6,
              iconId: '',
            },
            {
              indicador: '',
              // estatus: 2,
              valor: 1,
              indicadorId: 7,
              iconId: 'suspension-ventral-3',
            },
          ],
          asimetrias: [
            {
              asimetria: 'I',
              // estatus: "I",
              valor: 'I',
              asimetriaId: 1,
            },
            {
              asimetria: 'D',
              // estatus: "D",
              valor: 'D',
              asimetriaId: 2,
            },
          ],
        },
      ],
    },
    postura: {
      titulo: 'Evaluación de la postura', // Título lateral
      valorSecciones: 0,
      valorSeccionesId: 0,
      secciones: [
        {
          subtitulo: 'Cabeza en sedentación',
          valorIndicador: 0,
          valorAsimetria: '',
          indicadores: [
            // Preguntas
            {
              indicador: '<span class="text-blod">Recta:</span> en la línea media',
              // estatus: 1,
              valor: 4,
              indicadorId: 1,
              iconId: 'cabeza-en-sedestación-1',
            },
            {
              indicador: '',
              // estatus: 1,
              valor: 3.5,
              indicadorId: 2,
              iconId: '',
            },
            {
              indicador: '',
              // estatus: 2,
              valor: 3,
              indicadorId: 3,
              size: 'middle',
              iconId: '',
            },
            {
              indicador: '',
              // estatus: 1,
              valor: 2.5,
              indicadorId: 4,
              iconId: '',
            },
            {
              indicador:
                '<span class="text-blod">Ligeramente inclinada</span> lateral o hacia atrás o delante',
              // estatus: 2,
              valor: 2,
              indicadorId: 5,
              iconId: 'cabeza-en-sedestación-2',
              iconsIds: ['cabeza-en-sedestación-2-L', 'cabeza-en-sedestación-2-R']
            },
            {
              indicador: '',
              // estatus: 1,
              valor: 1.5,
              indicadorId: 6,
              iconId: '',
            },
            {
              indicador: '<span class="text-blod">Marcadamente inclinada</span> lateral o atrás o adelante',
              // estatus: 2,
              valor: 1,
              indicadorId: 7,
              iconId: 'cabeza-en-sedestación-3',
              iconsIds: ['cabeza-en-sedestación-3-L', 'cabeza-en-sedestación-3-R']
            },
          ],
          asimetrias: [
            {
              asimetria: 'I',
              // estatus: "I",
              valor: 'I',
              asimetriaId: 1,
            },
            {
              asimetria: 'D',
              // estatus: "D",
              valor: 'D',
              asimetriaId: 2,
            },
          ],
        },
        {
          subtitulo: 'Tronco en sedentación',
          valorIndicador: 0,
          valorAsimetria: '',
          indicadores: [
            {
              indicador: '<span class="text-blod">Recto</span>',
              // estatus: 0,
              valor: 4,
              indicadorId: 1,
              iconId: 'tronco-en-sedestación-1',
            },
            {
              indicador: '',
              // estatus: 1,
              valor: 3.5,
              indicadorId: 2,
              iconId: '',
            },
            {
              indicador: '',
              // estatus: 2,
              valor: 3,
              indicadorId: 3,
              size: 'middle',
              iconId: '',
            },
            {
              indicador: '',
              // estatus: 1,
              valor: 2.5,
              indicadorId: 4,
              iconId: '',
            },
            {
              indicador: '<span class="text-blod">Ligeramente encorvado</span> o inclinado hacia un lado',
              // estatus: 2,
              valor: 2,
              indicadorId: 5,
              iconId: 'tronco-en-sedestación-2',
            },
            {
              indicador: '',
              // estatus: 1,
              valor: 1.5,
              indicadorId: 6,
              iconId: '',
            },
            {
              indicador: '',
              // estatus: 2,
              valor: 1,
              indicadorId: 7,
              iconId: 'tronco-en-sedestación-3',
              iconsIds: ['tronco-en-sedestación-3-L', 'tronco-en-sedestación-3-C', 'tronco-en-sedestación-3-R'],
              textIcons: ['Muy <br /> curvado', 'Hiperextendido<br /> hacia atrás', 'Se doble<br /> hacia un lado']
            },
          ],
          asimetrias: [
            {
              asimetria: 'I',
              // estatus: "I",
              valor: 'I',
              asimetriaId: 1,
            },
            {
              asimetria: 'D',
              // estatus: "D",
              valor: 'D',
              asimetriaId: 2,
            },
          ],
        },
        {
          subtitulo: 'Brazos en reposo',
          valorIndicador: 0,
          valorAsimetria: '',
          indicadores: [
            {
              indicador:
                'En posición <span class="text-blod">neutra</span>, centrados o ligeramente flexionados',
              // estatus: 0,
              valor: 4,
              indicadorId: 1,
              iconId: '',
            },
            {
              indicador: '',
              // estatus: 1,
              valor: 3.5,
              indicadorId: 2,
              iconId: '',
            },
            {
              indicador: '',
              // estatus: 2,
              valor: 3,
              indicadorId: 3,
              size: 'middle',
              iconId: '',
            },
            {
              indicador: '',
              // estatus: 1,
              valor: 2.5,
              indicadorId: 4,
              iconId: '',
            },
            {
              indicador:
                'Rotación interna o externa <span class="text-blod">leve-modelada</span> postura distónica <span class="text-blod">intermitente</span>',
              // estatus: 2,
              valor: 2,
              indicadorId: 5,
              iconId: '',
            },
            {
              indicador: '',
              // estatus: 1,
              valor: 1.5,
              indicadorId: 6,
              iconId: '',
            },
            {
              indicador:
                '<span class="text-blod">Marcada rotación</span> externa o interna postura distónica o hemipléjica <span class="text-blod">mantenida</span>',
              // estatus: 2,
              valor: 1,
              indicadorId: 7,
              iconId: '',
            },
          ],
          asimetrias: [
            {
              asimetria: 'I',
              // estatus: "I",
              valor: 'I',
              asimetriaId: 1,
            },
            {
              asimetria: 'D',
              // estatus: "D",
              valor: 'D',
              asimetriaId: 2,
            },
          ],
        },
        {
          subtitulo: 'Manos',
          valorIndicador: 0,
          valorAsimetria: '',
          indicadores: [
            {
              indicador: 'Manos <span class="text-blod">abiertas</span>',
              // estatus: 0,
              valor: 4,
              indicadorId: 1,
              iconId: '',
            },
            {
              indicador: '',
              // estatus: 1,
              valor: 3.5,
              indicadorId: 2,
              iconId: '',
            },
            {
              indicador: '',
              // estatus: 2,
              valor: 3,
              indicadorId: 3,
              iconId: '',
              size: 'middle',
            },
            {
              indicador: '',
              // estatus: 1,
              valor: 2.5,
              indicadorId: 4,
              iconId: '',
            },
            {
              indicador:
                'Pulgar <span class="text-blod">aducto</span> y/o manos cerradas de forma <span class="text-blod">itermitente</span>',
              // estatus: 2,
              valor: 2,
              indicadorId: 5,
              iconId: '',
            },
            {
              indicador: '',
              // estatus: 1,
              valor: 1.5,
              indicadorId: 6,
              iconId: '',
            },
            {
              indicador: 'Pulgar <span class="text-blod">aducto</span> y/o manos cerradas de forma <span class="text-blod">mantenida</span>',
              // estatus: 2,
              valor: 1,
              indicadorId: 7,
              iconId: '',
            },
          ],
          asimetrias: [
            {
              asimetria: 'I',
              // estatus: "I",
              valor: 'I',
              asimetriaId: 1,
            },
            {
              asimetria: 'D',
              // estatus: "D",
              valor: 'D',
              asimetriaId: 2,
            },
          ],
        },
        {
          subtitulo: 'Piernas',
          valorIndicador: 0,
          valorAsimetria: '',
          indicadores: [
            {
              indicador:
                '<span class="span__info">(Sedestación)</span><br />Puede estar sentado <span class="text-blod">Espalda recta pienas</span> extendidas o ligeramente flexionadas',
              indicador2: '<span class="span__info">(supino, bipedestación)</span><br />Las piernas en posición <span class="text-blod">neutra rectas</span> o ligeram. flexionadas',
              // estatus: 0,
              valor: 4,
              indicadorId: 1,
              iconId: 'piernas-en-sedestación-1',
            },
            {
              indicador: '',
              // estatus: 1,
              valor: 3.5,
              indicadorId: 2,
              iconId: '',
            },
            {
              indicador: '<span class="text-blod">Ligera</span> rotación interna o rotación externa',
              // estatus: 2,
              size: 'middle',
              valor: 3,
              indicadorId: 3,
              iconId: '',
            },
            {
              indicador: '',
              // estatus: 1,
              valor: 2.5,
              indicadorId: 4,
              iconId: '',
            },
            {
              indicador:
                'Puede estar sentado mantiene la <span class="text-blod">espalda recta</span> pero las rodillas flexionadas 15-20º',
              indicador2: '<span class="span__info">(supino, bipedestación)</span><br /><span class="text-blod">Rotación marcada</span>, interna o externa, a la altura de las caderas',
              // estatus: 2,
              valor: 2,
              indicadorId: 5,
              iconId: 'piernas-en-sedestación-2',
            },
            {
              indicador: '',
              // estatus: 1,
              valor: 1.5,
              indicadorId: 6,
              iconId: '',
            },
            {
              indicador:
                '<span class="text-blod">No</span> puede estar <span class="text-blod">sentado</span> con espalda y piernas rectas, solo si están marcadamente flexionadas',
              indicador2: '<span class="span__info">(supino, bipedestación)</span><br /><span class="text-blod">Postura fija</span> en flexión o en extensión o contracturas a nivel de caderas y rodillas',
              // estatus: 2,
              valor: 1,
              indicadorId: 7,
              iconId: 'piernas-en-sedestación-3',
            },
          ],
          asimetrias: [
            {
              asimetria: 'I',
              // estatus: "I",
              valor: 'I',
              asimetriaId: 1,
            },
            {
              asimetria: 'D',
              // estatus: "D",
              valor: 'D',
              asimetriaId: 2,
            },
          ],
        },
        {
          subtitulo: 'Pies',
          valorIndicador: 0,
          valorAsimetria: '',
          indicadores: [
            {
              indicador:
                '<span class="text-blod">Pies rectos</span> en posición neutra <span class="text-blod">Dedos</span> rectos entre flexión y extensión',
              // estatus: 0,
              valor: 4,
              indicadorId: 1,
              iconId: '',
            },
            {
              indicador: '',
              // estatus: 1,
              valor: 3.5,
              indicadorId: 2,
              iconId: '',
            },
            {
              indicador: '',
              // estatus: 2,
              valor: 3,
              indicadorId: 3,
              iconId: '',
              size: 'middle',
            },
            {
              indicador: '',
              // estatus: 1,
              valor: 2.5,
              indicadorId: 4,
              iconId: '',
            },
            {
              indicador:
                '<span class="text-blod">Ligera</span> rotación interna o externa Tendencia a <span class="text-blod">puntillas</span> o <span class="text-blod">dedos hiperextendidos</span> o encogidos de forma <span class="text-blod">intermitente</span>',
              // estatus: 2,
              valor: 2,
              indicadorId: 5,
              iconId: '',
            },
            {
              indicador: '',
              // estatus: 1,
              valor: 1.5,
              indicadorId: 6,
              iconId: '',
            },
            {
              indicador:
                '<span class="text-blod">Marcada</span> rotación interna o externa de tobillo Tendencia <span class="text-blod">continua</span> a <span class="text-blod">puntillas</span>; o <span class="text-blod">dedos hiperextendidos</span> o engogidos (garra)',
              // estatus: 2,
              valor: 1,
              indicadorId: 7,
              iconId: '',
            },
          ],
          asimetrias: [
            {
              asimetria: 'I',
              // estatus: "I",
              valor: 'I',
              asimetriaId: 1,
            },
            {
              asimetria: 'D',
              // estatus: "D",
              valor: 'D',
              asimetriaId: 2,
            },
          ],
        },
      ],
    },
    reflejosReacciones: {
      titulo: 'Reflejos y reacciones postulares', // Título lateral
      valorSecciones: 0,
      valorSeccionesId: 0,
      secciones: [
        {
          subtitulo: 'Reflejos osteotendinosos',
          valorIndicador: 0,
          valorAsimetria: '',
          indicadores: [
            // Preguntas
            {
              indicador: 'Se obtienen con <span class="text-blod">facilidad</span>: biceps, rodilla y tobillo',
              // estatus: 1,
              valor: 4,
              indicadorId: 1,
              iconId: '',
            },
            {
              indicador: '',
              // estatus: 1,
              valor: 3.5,
              indicadorId: 2,
              iconId: '',
            },
            {
              indicador: '<span class="text-blod">Ligeramente exaltados</span>: biceps, rodilla y tobillo',
              // estatus: 2,
              valor: 3,
              indicadorId: 3,
              iconId: '',
            },
            {
              indicador: '',
              // estatus: 1,
              valor: 2.5,
              indicadorId: 4,
              iconId: '',
            },
            {
              indicador: '<span class="text-blod">Exaltados</span>: biceps, rodilla y tobillo',
              // estatus: 2,
              valor: 2,
              indicadorId: 5,
              iconId: '',
            },
            {
              indicador: '',
              // estatus: 1,
              valor: 1.5,
              indicadorId: 6,
              iconId: '',
            },
            {
              indicador: '<span class="text-blod">Clonus o ausentes</span>: biceps, rodilla y tobillo',
              // estatus: 2,
              valor: 1,
              indicadorId: 7,
              iconId: '',
            },
          ],
          asimetrias: [
            {
              asimetria: 'I',
              // estatus: "I",
              valor: 'I',
              asimetriaId: 1,
            },
            {
              asimetria: 'D',
              // estatus: "D",
              valor: 'D',
              asimetriaId: 2,
            },
          ],
        },
        {
          subtitulo: 'Protección del brazo',
          valorIndicador: 0,
          valorAsimetria: '',
          indicadores: [
            {
              indicador: 'Brazo y mano <span class="text-blod">extendidos</span>',
              // estatus: 0,
              valor: 4,
              indicadorId: 1,
              iconId: 'proteccion-del-brazo-1',
              DI: 1,
            },
            {
              indicador: '',
              // estatus: 1,
              valor: 3.5,
              indicadorId: 2,
              iconId: '',
            },
            {
              indicador: '',
              // estatus: 2,
              valor: 3,
              indicadorId: 3,
              iconId: '',
            },
            {
              indicador: '',
              // estatus: 1,
              valor: 2.5,
              indicadorId: 4,
              iconId: '',
            },
            {
              indicador: 'Brazo <span class="text-blod">semiflexionado</span>',
              // estatus: 2,
              valor: 2,
              indicadorId: 5,
              iconId: 'proteccion-del-brazo-2',
              DI: 1,
            },
            {
              indicador: '',
              // estatus: 1,
              valor: 1.5,
              indicadorId: 6,
              iconId: '',
            },
            {
              indicador: 'Blazo <span class="text-blod">completamente</span> flexionado',
              // estatus: 2,
              valor: 1,
              indicadorId: 7,
              iconId: 'proteccion-del-brazo-3',
              DI: 1,
            },
          ],
          asimetrias: [
            {
              asimetria: 'I',
              // estatus: "I",
              valor: 'I',
              asimetriaId: 1,
            },
            {
              asimetria: 'D',
              // estatus: "D",
              valor: 'D',
              asimetriaId: 2,
            },
          ],
        },
        {
          subtitulo: 'Suspensión vertical',
          valorIndicador: 0,
          valorAsimetria: '',
          indicadores: [
            {
              indicador: '<span class="text-blod">Simétrico</span> y <span class="text-blod">alternante</span>',
              // estatus: 0,
              valor: 4,
              indicadorId: 1,
              iconId: 'suspension-vertical-1',
              iconsIds: ['suspension-vertical-1-L', 'suspension-vertical-1-R']
            },
            {
              indicador: '',
              // estatus: 1,
              valor: 3.5,
              indicadorId: 2,
              iconId: '',
            },
            {
              indicador: '',
              // estatus: 2,
              valor: 3,
              indicadorId: 3,
              iconId: '',
            },
            {
              indicador: '',
              // estatus: 1,
              valor: 2.5,
              indicadorId: 4,
              iconId: '',
            },
            {
              indicador: '<span class="text-blod">Una</span> pierna patalea más, o <span class="text-blod">pataleo pobre</span>',
              // estatus: 2,
              valor: 2,
              indicadorId: 5,
              iconId: 'suspension-vertical-2',
              iconsIds: ['suspension-vertical-2-L', 'suspension-vertical-2-R']
            },
            {
              indicador: '',
              // estatus: 1,
              valor: 1.5,
              indicadorId: 6,
              iconId: '',
            },
            {
              indicador:
                '<span class="text-blod">No</span> patalea incluso si es estimulado, o adopta postura en "<span class="text-blod">tijera</span>"',
              // estatus: 2,
              valor: 1,
              indicadorId: 7,
              iconId: 'suspension-vertical-3',
              iconsIds: ['suspension-vertical-3-L', 'suspension-vertical-3-R']
            },
          ],
          asimetrias: [
            {
              asimetria: 'I',
              // estatus: "I",
              valor: 'I',
              asimetriaId: 1,
            },
            {
              asimetria: 'D',
              // estatus: "D",
              valor: 'D',
              asimetriaId: 2,
            },
          ],
        },
        {
          subtitulo: 'Suspensión lateral',
          valorIndicador: 0,
          valorAsimetria: '',
          indicadores: [
            {
              indicador: '',
              // estatus: 0,
              valor: 4,
              indicadorId: 1,
              iconId: 'suspencion-lateral-1',
              DI: 1,
            },
            {
              indicador: '',
              // estatus: 1,
              valor: 3.5,
              indicadorId: 2,
              iconId: '',
            },
            {
              indicador: '',
              // estatus: 2,
              valor: 3,
              indicadorId: 3,
              iconId: 'suspencion-lateral-2',
              DI: 1,
            },
            {
              indicador: '',
              // estatus: 1,
              valor: 2.5,
              indicadorId: 4,
              iconId: '',
            },
            {
              indicador: '',
              // estatus: 2,
              valor: 2,
              indicadorId: 5,
              iconId: 'suspencion-lateral-3',
              DI: 1,
            },
            {
              indicador: '',
              // estatus: 1,
              valor: 1.5,
              indicadorId: 6,
              iconId: '',
            },
            {
              indicador: '',
              // estatus: 2,
              valor: 1,
              indicadorId: 7,
              iconId: 'suspencion-lateral-4',
              DI: 1,
            },
          ],
          asimetrias: [
            {
              asimetria: 'I',
              // estatus: "I",
              valor: 'I',
              asimetriaId: 1,
            },
            {
              asimetria: 'D',
              // estatus: "D",
              valor: 'D',
              asimetriaId: 2,
            },
          ],
        },
        {
          subtitulo: 'Paracaídas',
          valorIndicador: 0,
          valorAsimetria: '',
          indicadores: [
            {
              indicador: '(después de los 6 meses)',
              // estatus: 0,
              valor: 4,
              indicadorId: 1,
              iconId: 'paracaidas-1',
            },
            {
              indicador: '',
              // estatus: 1,
              valor: 3.5,
              indicadorId: 2,
              iconId: '',
            },
            {
              indicador: '',
              // estatus: 2,
              valor: 3,
              indicadorId: 3,
              iconId: '',
            },
            {
              indicador: '',
              // estatus: 1,
              valor: 2.5,
              indicadorId: 4,
              iconId: '',
            },
            {
              indicador: '(después de los 6 meses)',
              // estatus: 2,
              valor: 2,
              indicadorId: 5,
              iconId: 'paracaidas-2',
            },
            {
              indicador: '',
              // estatus: 1,
              valor: 1.5,
              indicadorId: 6,
              iconId: '',
            },
            {
              indicador: '',
              // estatus: 2,
              valor: 1,
              indicadorId: 7,
              iconId: '',
            },
          ],
          asimetrias: [
            {
              asimetria: 'I',
              // estatus: "I",
              valor: 'I',
              asimetriaId: 1,
            },
            {
              asimetria: 'D',
              // estatus: "D",
              valor: 'D',
              asimetriaId: 2,
            },
          ],
        },
      ],
    },
  },
};
