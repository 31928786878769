import React, { useEffect, useState } from 'react';
import classnames from 'classnames'
import localforage from 'localforage'

const AutoComplete = ({ open, setOpen, setValue, element }) => {
    const [elements, setElements] = useState([])

    useEffect(() => {
        if(element && element.getAttribute("data-localforage")){
            const keyLocalforage = element.getAttribute("data-localforage")
            const loadData = async () => {
                const array = await localforage.getItem(keyLocalforage)
                console.log("localforage Array",keyLocalforage)
                const valueElement = element.value
                if(array){
                    if(valueElement){
                        if(!array.includes(valueElement)){
                            let updateArray = [...array, valueElement]
                            if (updateArray.length > 5) {
                                updateArray = updateArray.slice(-5)
                                await localforage.setItem(keyLocalforage, updateArray)
                            }
                            else{
                                await localforage.setItem(keyLocalforage, updateArray)
                            }
                            
                        }
                    }
                    setElements(array)
                }
                else{
                    if(valueElement){
                        await localforage.setItem(keyLocalforage, [valueElement])
                    }
                    const oneArray = await localforage.getItem(keyLocalforage)
                    setElements(oneArray)
                }
            }
            loadData()
        }
    },[element, open])

    function handleClick(item) {
        setValue(element.getAttribute("name"), item)
        setOpen(false)
    }

    return (
        <div 
        id="popperEl" 
        className={
            classnames('z-9 inline-flex justify-center w-auto py-2 text-sm font-medium leading-5 text-gray-700 transition duration-150 ease-in-out',
            ' !bg-gray-800 backdrop-opacity-60 text-white backdrop-blur border border-gray-300 rounded-md hover:text-gray-500 ',
            'focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:bg-gray-50 active:text-gray-800 animate-fade',
            !open && 'hidden',
            !elements && 'border-0'
            )
        }
        >
          <ul className='m-0 p-0'>
            {elements && elements.map((item, index) => <li key={index} className='px-4 py-2 hover:bg-gray-600 w-full' onClick={() => {handleClick(item)}}>{item}</li>)}
          </ul>
        </div>
    )
}

export default AutoComplete