// estatus -> Indica el color: 0 = negro, 1 = verde, 2 = rojo
export const MotorRepertoireType = {
  id: '',
  patientId: '', // Cuando es del formulario de valoración
  neuroMonitoringId: '', // Cuando es del formulario de neuroseguimiento
  neuroMonitoringPatientId: '', // Cuando es del formulario de neuroseguimiento
  doctorIdCreated: '',
  timestamp: '',
  createdAt: '',
  updatedAt: '',
  movimientosFidgety: {
    titulo: 'Movimientos Fidgety',
    valorSeleccionado: '',
    indicadores: {
      movimientoFidgety: {
        indicador: '(F) Movimientos fidgety',
        estatus: 1,
        valor: 'F',
      },
      anormalExagerado: {
        indicador: '(AF) Anormal exagerado',
        estatus: 2,
        valor: 'AF',
      },
      ausente: {
        indicador: '(F-) Ausente',
        estatus: 2,
        valor: 'F-',
      },
      esporadico: {
        indicador: 'Esporádico (específico para la edad)',
        estatus: 0,
        valor: 'FeN',
        valor2: 'FeA',
      },
    },
  },
  patronesMovObs: {
    titulo: 'Patrones de movimiento observados',
    valorNormal: 0,
    valorAtipico: 0,
    indicadores: [
      {
        tipoIndicador: 'cerrado',
        valorSeleccionado: '',
        indicador: 'Movimientos de manoteo',
        valorNormal: 'N',
        valorAtipico: 'A',
      },
      {
        tipoIndicador: 'cerrado',
        valorSeleccionado: '',
        indicador: 'Contacto mano-boca',
        valorNormal: 'N',
        valorAtipico: 'A',
      },
      {
        tipoIndicador: 'cerrado',
        valorSeleccionado: '',
        indicador: 'Arqueo',
        valorNormal: 'N',
        valorAtipico: 'A',
      },
      {
        tipoIndicador: 'cerrado',
        valorSeleccionado: '',
        indicador: 'Movi. de meneo oscilante',
        valorNormal: 'N',
        valorAtipico: 'A',
      },
      {
        tipoIndicador: 'cerrado',
        valorSeleccionado: '',
        indicador: 'Contacto mano-mano',
        valorNormal: 'N',
        valorAtipico: 'A',
      },
      {
        tipoIndicador: 'cerrado',
        valorSeleccionado: '',
        indicador: 'Rueda de lado',
        valorNormal: 'N',
        valorAtipico: 'A',
      },
      {
        tipoIndicador: 'cerrado',
        valorSeleccionado: '',
        indicador: 'Pataleo',
        valorNormal: 'N',
        valorAtipico: 'A',
      },
      {
        tipoIndicador: 'cerrado',
        valorSeleccionado: '',
        indicador: 'Jugueteo',
        valorNormal: 'N',
        valorAtipico: 'A',
      },
      {
        tipoIndicador: 'cerrado',
        valorSeleccionado: '',
        indicador: 'Exploración visual',
        valorNormal: 'N',
        valorAtipico: 'A',
      },
      {
        tipoIndicador: 'cerrado',
        valorSeleccionado: '',
        indicador: 'Estallidos de emoción',
        valorNormal: 'N',
        valorAtipico: 'A',
      },
      {
        tipoIndicador: 'cerrado',
        valorSeleccionado: '',
        indicador: 'Alcanzar',
        valorNormal: 'N',
      },
      {
        tipoIndicador: 'cerrado',
        valorSeleccionado: '',
        indicador: 'Mirar la mano',
        valorNormal: 'N',
      },
      {
        tipoIndicador: 'cerrado',
        valorSeleccionado: '',
        indicador: 'Sonrisas',
        valorNormal: 'N',
        valorAtipico: 'A',
      },
      {
        tipoIndicador: 'cerrado',
        valorSeleccionado: '',
        indicador: 'Contacto pie-pie',
        valorNormal: 'N',
        valorAtipico: 'A',
      },
      {
        tipoIndicador: 'cerrado',
        valorSeleccionado: '',
        indicador: 'Anteflexión de la cabeza',
        valorNormal: 'N',
        valorAtipico: 'A',
      },
      {
        tipoIndicador: 'cerrado',
        valorSeleccionado: '',
        indicador: 'Movimientos de la boca',
        valorNormal: 'N',
        valorAtipico: 'A',
      },
      {
        tipoIndicador: 'cerrado',
        valorSeleccionado: '',
        indicador: 'Elevación de piernas',
        valorNormal: 'N',
        valorAtipico: 'A',
      },
      {
        tipoIndicador: 'cerrado',
        valorSeleccionado: '',
        indicador: 'Movimientos del brazo en círculos',
        valorAtipico: 'A',
      },
      {
        tipoIndicador: 'cerrado',
        valorSeleccionado: '',
        indicador: 'Movimientos de la lengua',
        valorNormal: 'N',
        valorAtipico: 'A',
      },
      {
        tipoIndicador: 'cerrado',
        valorSeleccionado: '',
        indicador: 'Contacto con dedos de los pies',
        valorNormal: 'N',
      },
      {
        tipoIndicador: 'cerrado',
        valorSeleccionado: '',
        indicador: 'Casi sin movs. de piernas',
        valorAtipico: 'A',
      },
      {
        tipoIndicador: 'cerrado',                                                                  
        valorSeleccionado: '',
        indicador: 'Movimientos laterales de la cabeza',
        valorNormal: 'N',
        valorAtipico: 'A',
      },
      {
        tipoIndicador: 'cerrado',
        valorSeleccionado: '',
        indicador: 'Movs. segment. de los dedos y muñecas',
        valorAtipico: 'A',
      },
      {
        tipoIndicador: 'abierto',
        valorSeleccionado: '',
        indicador: '',
      },
    ],
  },
  repMovAdecEdad: {
    titulo:
      'Puntuación del repertorio motor para la edad (MOS) en niños de 3-5 meses',
    edadCorregidaSemanas: '',
    valorSeleccionado: '',
    indicadores9a11: {
      indicador1: { text: '4 Normales', valor: 4 },
      indicador2: { text: '3 Normales', valor: 2 },
      indicador3: { text: '1, 2 Normales', valor: 1 },
    },
    indicadores12a13: {
      indicador1: { text: 'Pie-pie + 3 normales', valor: 4 },
      indicador2: { text: '4 Normales (no pie-pie)', valor: 2 },
      indicador3: { text: '< 4 Normales', valor: 1 },
    },
    indicadores14a15: {
      indicador1: { text: 'Pie-pie, mano-mano + 2 normales', valor: 4 },
      indicador2: { text: 'Pie-pie o mano-mano + 3 normales', valor: 2 },
      indicador3: { text: 'No se observa ni pie-pie ni mano-mano', valor: 1 },
    },
    indicadores16omas: {
      indicador1: {
        text: 'Pie-pie + mano-mano + elevación de piernas + 1 normal',
        valor: 4,
      },
      indicador2: {
        text: '2 de los mencionados arriba + 2 normales',
        valor: 2,
      },
      indicador3: { text: 'Solo de 1 de los anteriores o ninguno', valor: 1 },
    },
  },
  patronesPostObs: {
    titulo: 'Patrones posturales observados',
    valorNormal: 0,
    valorAtipico: 0,
    indicadores: [
      {
        tipoIndicador: 'cerrado',
        valorSeleccionado: '',
        indicador: 'Cabeza en la línea media',
        valorNormal: 'N',
        valorAtipico: 'A',
      },
      {
        tipoIndicador: 'cerrado',
        valorSeleccionado: '',
        indicador: 'Posturas variables de los dedos',
        valorNormal: 'N',
        valorAtipico: 'A',
      },
      {
        tipoIndicador: 'cerrado',
        valorSeleccionado: '',
        indicador: 'Hiperextensión del cuello',
        valorAtipico: 'A',
      },
      {
        tipoIndicador: 'cerrado',
        valorSeleccionado: '',
        indicador: 'Cuerpo simétrico',
        valorNormal: 'N',
        valorAtipico: 'A',
      },
      {
        tipoIndicador: 'cerrado',
        valorSeleccionado: '',
        indicador: 'Puño predominante',
        valorAtipico: 'A',
      },
      {
        tipoIndicador: 'cerrado',
        valorSeleccionado: '',
        indicador: 'Hiperextensión del tronco',
        valorAtipico: 'A',
      },
      {
        tipoIndicador: 'cerrado',
        valorSeleccionado: '',
        indicador: 'ATNR espont. ausente o podría ser superado',
        valorNormal: 'N',
        valorAtipico: 'A',
      },
      {
        tipoIndicador: 'cerrado',
        valorSeleccionado: '',
        indicador: 'Sincronización de apertura y cierre de los dedos',
        valorAtipico: 'A',
      },
      {
        tipoIndicador: 'cerrado',
        valorSeleccionado: '',
        indicador: 'Brazos extendidos',
        valorAtipico: 'A',
      },
      {
        tipoIndicador: 'cerrado',
        valorSeleccionado: '',
        indicador: 'Cuerpo y extremidades "planos" sobre la superficie',
        valorAtipico: 'A',
      },
      {
        tipoIndicador: 'cerrado',
        valorSeleccionado: '',
        indicador: 'Separación de dedos',
        valorAtipico: 'A',
      },
      {
        tipoIndicador: 'cerrado',
        valorSeleccionado: '',
        indicador: 'Piernas extendidas',
        valorAtipico: 'A',
      },
      {
        tipoIndicador: null,
        valorSeleccionado: null,
        indicador: null,
        valorAtipico: null,
      },
      {
        tipoIndicador: 'cerrado',
        valorSeleccionado: '',
        indicador: 'Asimetría de la postura de los dedos',
        valorAtipico: 'A',
      },
      {
        tipoIndicador: 'abierto',
        valorSeleccionado: '',
        valorEscrito: '',
        indicador: '',
        valorAtipico: 'A',
      },
    ],
  },
  caracterMovimientos: {
    titulo: 'Caracter de los movimientos (puntaje global)',
    indicadores: [
      {
        tipoIndicador: 'cerrado',
        valorSeleccionado: '',
        indicador: 'Suave y fluido',
        valorNormal: 'N',
        valorAtipico: 'A',
      },
      {
        tipoIndicador: 'cerrado',
        valorSeleccionado: '',
        indicador: 'Rígido',
        valorAtipico: 'A',
      },
      {
        tipoIndicador: 'cerrado',
        valorSeleccionado: '',
        indicador: 'Predominantemente lento',
        valorAtipico: 'A',
      },
      {
        tipoIndicador: 'cerrado',
        valorSeleccionado: '',
        indicador: 'Monótono',
        valorAtipico: 'A',
      },
      {
        tipoIndicador: 'cerrado',
        valorSeleccionado: '',
        indicador: 'Tembloroso',
        valorAtipico: 'A',
      },
      {
        tipoIndicador: 'cerrado',
        valorSeleccionado: '',
        indicador: 'Predominantemente rápido',
        valorAtipico: 'A',
      },
      {
        tipoIndicador: 'cerrado',
        valorSeleccionado: '',
        indicador: 'Brusco',
        valorAtipico: 'A',
      },
      {
        tipoIndicador: 'cerrado',
        valorSeleccionado: '',
        indicador: 'Cramped-synchronized',
        valorAtipico: 'A',
      },
      {
        tipoIndicador: 'abierto',
        valorSeleccionado: '',
        valorEscrito: '',
        indicador: '',
        valorAtipico: 'A',
      },
    ],
  },
  optimalidadMotora: {
    movimientosFidgety: {
      valorSeleccionado: 0,
      normal: { text: 'Normal', valor: 12 },
      anormalExagerado: { text: 'Anormal/exagerado', valor: 4 },
      ausenteEsporadico: { text: 'Ausente/esporádico', valor: 1 },
    },
    patronesMovObs: {
      valorSeleccionado: 0,
      nMayorA: { text: 'N > A', valor: 4 },
      nIgualA: { text: 'N = A', valor: 2 },
      nMenorA: { text: 'N < A', valor: 1 },
    },
    movimientosAdecEdad: {
      valorSeleccionado: 0,
      nMayorA: { text: 'Presente', valor: 4 },
      nIgualA: { text: 'Reducido', valor: 2 },
      nMenorA: { text: 'Ausente', valor: 1 },
    },
    patronesPostObs: {
      valorSeleccionado: 0,
      nMayorA: { text: 'N > A', valor: 4 },
      nIgualA: { text: 'N = A', valor: 2 },
      nMenorA: { text: 'N < A', valor: 1 },
    },
    caracterMovimientos: {
      valorSeleccionado: 0,
      nMayorA: { text: 'Suave y fluido', valor: 4 },
      nIgualA: { text: 'Anormal, pero no Cs', valor: 2 },
      nMenorA: { text: 'Cramped-synchronised', valor: 1 },
    },
  },
  puntuacionOptimalidadMotora: 0,
};
