import { getCurrentDate } from '../utils/formulas';

export const NeuroMonitoringType = {
  control: '',
  onlyControl: false,
  date: getCurrentDate(),
  sg: '',
  description: '',
  exploration: '',
  cxObjetives: '',
  reassessment: '',
  documents: [],
  dn: '',
  eCron: '',
  eco: '',
  ecoWeeks: '',
  leftTime: '',
  bornDays: '',
  movementType: '', // Mov. prematuro (N, PR), Mov. writhing (N, PR, CS), Transicion, Fidgety (F, AF, F), Mov. Antigrav.
  images: [],
  number: '',
  result: '',
  score: '',
  riskLevel: '',
  videos: [],
  weight: '',
  size: '',
  // PERÍMETRO CEFÁLICO (CM)
  headCircunference: '',
  // PLAGIOCEFALIA --> Index plagiocefálico (mm) = |A-B|
  plagiocephalyIndexA: null,
  plagiocephalyIndexB: null,
  // BRAQUICEFALIA Y ESCAFOCEFALIA --> Index craneal (IC %) = (SD/AP)*100
  brachyphalySd: null,
  brachyphalyAp: null,
  // idRegister: '',
  // timestamp: '',
  // doctorAssigned: '', // Agregado en el frente para pintarlo solamente
  // createdAt: '', // Agregado en el frente para pintarlo solamente
  doctorIdAssigned: '',
  doctorsList: '',
  form12weeks: '',
  doctorReferences: {
    neurologist: {
      doctorType: 'Neurólogo',
      diagnosis: '',
      treatment: '',
      suggestions: '',
      notes: '',
    },
    traumatologist: {
      doctorType: 'Traumatólogo',
      diagnosis: '',
      treatment: '',
      suggestions: '',
      notes: '',
    },
    gastroenterologist: {
      doctorType: 'Gastroenterólogo',
      diagnosis: '',
      treatment: '',
      suggestions: '',
      notes: '',
    },
    cardiologist: {
      doctorType: 'Cardiólogo',
      diagnosis: '',
      treatment: '',
      suggestions: '',
      notes: '',
    },
    dermatologist: {
      doctorType: 'Dermatólogo',
      diagnosis: '',
      treatment: '',
      suggestions: '',
      notes: '',
    },
    dentist: {
      doctorType: 'Odontólogo',
      diagnosis: '',
      treatment: '',
      suggestions: '',
      notes: '',
    },
    neonatologist: {
      doctorType: 'Neonatólogo/Pediatra',
      diagnosis: '',
      treatment: '',
      suggestions: '',
      notes: '',
    },
    pulmonologist: {
      doctorType: 'Neumólogo',
      diagnosis: '',
      treatment: '',
      suggestions: '',
      notes: '',
    },
    ophthalmologist: {
      doctorType: 'Oftalmólogo',
      diagnosis: '',
      treatment: '',
      suggestions: '',
      notes: '',
    },
    otolaryngologist: {
      doctorType: 'Otorrinolaringólogo',
      diagnosis: '',
      treatment: '',
      suggestions: '',
      notes: '',
    },
    genetics: {
      doctorType: 'Genética',
      diagnosis: '',
      treatment: '',
      suggestions: '',
      notes: '',
    },
  },
};
