export const UserModel = {
  username: '',
  email: '',
  password: '',
  confirm_password: '', // solo en código, no en db
  role: '', // Administrador, Editor, Colaborador
  profile_pic_url: '',
  profile_pic_name: '',
  // number: 0
  // timestamp: ''
  // is_active: true,
  oneSignalKeys: {
    key: '',
    date: '',
    timestapm: '',
  },
};
