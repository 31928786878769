import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import confirm from 'reactstrap-confirm';
import { PDFDownloadLink } from '@react-pdf/renderer';
import {
  Row, Col, Button, Spinner, Container,
} from 'reactstrap';
import { useForm } from 'react-hook-form';
import moment from 'moment';
import authHOC from '../utils/authHOC';
import { monthOptions } from '../utils/monthOptions';
import { getCurrentYear } from '../utils/formulas';
import {
  deleteMonitoringPatientValorationById,
  deleteMultipleFiles,
  deleteProfilePicByName,
  deletePatientValorationById,
  freePatientValorationById,
  getAllYearRange,
  stopPatientValorationById,
  getPatientsPaginated,
  getPatientsByNameAndDoctor,
  getPatientValorationsByYearMonthStatus,
  getPatientValorationsComplete,
} from '../actions/PatientAction';
import Navbar from '../common/Navbar';
import Menu from '../common/Menu';
import CustomAlert from '../common/CustomAlert';
import CustomModal from '../common/CustomModal';
import InputFormGroup from '../common/InputFormGroup';
import PatientsContainerListItems from '../components/PatientsContainerListItems';
import PatientValorationPdf from '../components/PatientValorationPdf';
import RowPatientValorationTable from '../components/RowPatientValorationTable';
import '../styles/filterPatientList.scss';
import { getAllUsersOptionsSearch } from '../actions/UserAction';

const typeUserOptions = [
  { name: 'Activos', value: 'active' },
  { name: 'Alta', value: 'free' },
  { name: 'Suspendidos', value: 'suspended' },
];

function searchStrInArray(arr, str) {
  const searchStr = str.toLowerCase();
  return arr.filter(obj => Object.values(obj).some(val =>
    typeof val === 'string' && val.toLowerCase().includes(searchStr)
  )).map(e => e.value);
}

const PatientList = ({ history }) => {
  const [alert, setAlert] = useState({ status: false, text: '', color: '' });
  const [patientValorationList, setPatientValorationList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [searchByYearMonthStatus, setSearchByYearMonthStatus] = useState(false);
  const [firstVisible, setFirstVisible] = useState(null);
  const [lastVisible, setLastVisible] = useState(null);
  const [prevLastVisible, setPrevLastVisible] = useState(null);
  const [activePage, setActivePage] = useState(1);
  const [errorPatientValorationList, setErrorPatientValorationList] = useState(false);
  const [dataUpdate, setDataUpdate] = useState(false);
  const [optionsYearRange, setOptionsYearRange] = useState([
    { name: getCurrentYear().toString(), value: getCurrentYear().toString() },
  ]);
  const [hideNavigation, setHideNavigation] = useState(false)
  const [userOptions, setUserOptions] = useState([]);
  const { control, errors, watch } = useForm({
    defaultValues: {
      yearFilter: null,
      monthFilter: null,
      typeFilter: null,
    },
  });

  const yearFilter = watch('yearFilter');
  const monthFilter = watch('monthFilter');
  const typeFilter = watch('typeFilter');

  const [modalIsOpen, setModalIsOpen] = useState(false);
  const toggleModal = () => setModalIsOpen((prevState) => !prevState);
  const [patientDataDownload, setPatientDataDownload] = useState();
  const fetchData = async () => {
    setIsLoading(true);
    try {
      const yearRange = await getAllYearRange();
      setOptionsYearRange(yearRange);
      const userOptions = await getAllUsersOptionsSearch();
      setUserOptions(userOptions);
    } catch (error) {
      setErrorPatientValorationList(true);
    } finally {
      setIsLoading(false);
    }
  };

  const handleSearchByYearMonthStatus = async () => {
    setActivePage(1);
    byYearMonthStatus();
  }

  const byYearMonthStatus = async (startAfterDoc = null, endBeforeDoc = null) => {
    setIsLoading(true);
    setSearchByYearMonthStatus(true);
    try {
      const { patients: fetchedPatients } = await getPatientValorationsByYearMonthStatus(
        yearFilter,
        monthFilter,
        typeFilter,
        startAfterDoc,
        endBeforeDoc
      );
      setPatientValorationList(fetchedPatients);
      setHideNavigation(false);
    } catch (error) {
      console.error('Error al obtener pacientes:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchPatients = async (startAfterDoc = null, endBeforeDoc = null) => {
    setIsLoading(true);
    try {
      const { patients: fetchedPatients, firstVisible: first, lastVisible: last } =
        await getPatientsPaginated(startAfterDoc, endBeforeDoc);
      setPatientValorationList(fetchedPatients);
      setHideNavigation(false);
      setFirstVisible(first);
      setLastVisible(last);
    } catch (error) {
      console.error('Error al obtener pacientes:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const handlePageChange = (pageNumber) => {
    setActivePage(pageNumber);
    const letFun = searchByYearMonthStatus && (yearFilter || monthFilter || typeFilter) ? byYearMonthStatus : fetchPatients
    if (pageNumber === 1) {
      letFun();
    } else if (pageNumber > activePage) {
      setPrevLastVisible(firstVisible);
      letFun(lastVisible, null);
    } else {
      letFun(lastVisible, firstVisible);
    }
  };

  useEffect(() => {
    fetchData();
    fetchPatients();
  }, []);

  useEffect(() => {
    if (dataUpdate) {
      fetchPatients();
    }
  }, [dataUpdate]);

  const searchOnCache = async (inputPatientValue) => {
    setIsLoading(true);
    // TODO: implements cache
    const result = await getPatientValorationsComplete();
    setIsLoading(false);
    console.log(result);
    let filterList = result.data;
    if (inputPatientValue) {
      filterList = filterList.filter(
        (item) => item.general.name
          .toLowerCase()
          .indexOf(inputPatientValue.toLowerCase()) > -1,
      );
      setPatientValorationList(filterList);
    }
  }

  const searchPatient = async () => {
    setActivePage(1);
    const inputPatientValue = document.getElementById('input-search-filter-patient').value;
    const inputDoctorValue = document.getElementById('input-search-filter-doctor').value;

    if (!inputPatientValue && !inputDoctorValue) {
      fetchPatients();
      return;
    }
    setIsLoading(true);
    try {
      const doctorsArr = inputDoctorValue ? searchStrInArray(userOptions, inputDoctorValue) : [];

      if (inputPatientValue || doctorsArr.length > 0) {
        const result = await getPatientsByNameAndDoctor(inputPatientValue, doctorsArr);
        if (result.patients.length) {
          setPatientValorationList(result.patients);
          setHideNavigation(true);
        }else{
          await searchOnCache(inputPatientValue);
        }
      } else {
        fetchPatients();
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleDownloadRegister = (patientData) => {
    let neuroMonitoringArray = [];
    if (patientData.neuroMonitoring) {
      neuroMonitoringArray = Object.values(patientData.neuroMonitoring);
      neuroMonitoringArray.sort((a, b) => {
        return b.timestamp - a.timestamp;
      });
    }
    Object.values(neuroMonitoringArray).forEach((neuroItem) => {
      neuroItem.createdAt = moment(neuroItem.timestamp.toDate()).format(
        'DD-MM-YYYY hh:mm:ss',
      );
      const doctor = userOptions.find((user) => {
        const doctorIdRegistered = neuroItem.doctorIdAssigned || '';
        return user.value === doctorIdRegistered;
      });
      neuroItem.doctorAssigned = doctor || '';
      return neuroItem;
    });
    patientData.neuroMonitoring = neuroMonitoringArray;
    setPatientDataDownload(patientData);
    toggleModal();
  };

  const handleDeleteRegister = async (item) => {
    const result = await confirm({
      title: '¿Estás seguro?',
      message: `Esta acción no se puede deshacer. Se borrará permanentemente el registro de ${item.general.name}`,
      confirmText: 'Sí, estoy seguro',
      cancelText: 'No, cancelar',
      confirmColor: 'success',
      cancelColor: 'danger',
    });

    if (result === false) return;
    setAlert({
      status: true,
      text: 'Eliminando registro...',
      color: 'blue-one',
    });

    let neuroMonitoringList = [];
    if (item.neuroMonitoring) {
      const objectToArray = Object.values(item.neuroMonitoring);
      objectToArray.sort((a, b) => {
        return b.number - a.number;
      });
      neuroMonitoringList = objectToArray;
    }

    await neuroMonitoringList.forEach(async (MonitoringItem) => {
      await deleteMonitoringPatientValorationById(
        item.id,
        MonitoringItem.idRegister,
      )
        .then(() => deleteMultipleFiles(MonitoringItem.videos, 'videos'))
        .then(() => deleteMultipleFiles(MonitoringItem.images, 'images'))
        .then(() => deleteMultipleFiles(MonitoringItem.documents, 'documents'))
        .then(() => null)
        .catch(() => null);
    });
    setAlert({
      status: true,
      text: 'Seguimientos eliminados con éxito',
      color: 'green',
    });

    if (item.profile_pic_name) {
      await deleteProfilePicByName(item.profile_pic_name)
        .then(() => null)
        .catch(() => null);
    }

    await deletePatientValorationById(item.id)
      .then((result) => {
        setAlert({ status: true, text: result.message, color: 'green' });
        const idx = patientValorationList.findIndex(
          (value) => value.id === item.id,
        );
        if (idx !== -1) {
          const newData = patientValorationList.splice(idx, idx);
          setPatientValorationList(newData);
          setHideNavigation(false);
          setDataUpdate(true);
        }
      })
      .catch((error) => {
        setAlert({
          status: true,
          text: error.message,
          color: 'green',
        });
      });
    setTimeout(() => setAlert({ status: false, text: '', color: '' }), 4000);
  };

  const handleFreeRegister = async (item) => {
    const result = await confirm({
      title: '¿Estás seguro?',
      message: `Esta acción no se puede deshacer. Se dará de alta permanentemente el registro de ${item.general.name}`,
      confirmText: 'Sí, estoy seguro',
      cancelText: 'No, cancelar',
      confirmColor: 'success',
      cancelColor: 'danger',
    });

    if (result === false) return;
    setAlert({
      status: true,
      text: 'Dando de alta al paciente...',
      color: 'blue-one',
    });

    await freePatientValorationById(item.id)
      .then((result) => {
        setAlert({ status: true, text: result.message, color: 'green' });
        setDataUpdate(true);
      })
      .catch((error) => {
        setAlert({
          status: true,
          text: error.message,
          color: 'green',
        });
      });
    setTimeout(() => setAlert({ status: false, text: '', color: '' }), 4000);
  };

  const handleStopRegister = async (item) => {
    const result = await confirm({
      title: '¿Estás seguro?',
      message: `Esta acción no se puede deshacer. Se suspenderá el registro de ${item.general.name}`,
      confirmText: 'Sí, estoy seguro',
      cancelText: 'No, cancelar',
      confirmColor: 'success',
      cancelColor: 'danger',
    });

    if (result === false) return;
    setAlert({
      status: true,
      text: 'Suspendiendo al paciente...',
      color: 'blue-one',
    });

    await stopPatientValorationById(item.id)
      .then((result) => {
        setAlert({ status: true, text: result.message, color: 'green' });
        setDataUpdate(true);
      })
      .catch((error) => {
        setAlert({
          status: true,
          text: error.message,
          color: 'green',
        });
      });
    setTimeout(() => setAlert({ status: false, text: '', color: '' }), 4000);
  };

  return (
    <>
      <Menu />
      <Navbar history={history} search={searchPatient} />
      <div id="page-wrap">
        {alert.status ? (
          <CustomAlert text={alert.text} color={alert.color} />
        ) : null}
        {errorPatientValorationList && (
          <CustomAlert text="Hubo un error al obtener la información, por favor recargue" />
        )}
        <PatientsContainerListItems
            title="Pacientes registrados"
            subtitle="Datos de pacientes"
            textButton="Agregar paciente"
            listData={patientValorationList}
            type="patient"
            link="/pacientes/formato/crear/nuevo"
            history={history}
            handleDeleteRegister={handleDeleteRegister}
            handleDownloadRegister={handleDownloadRegister}
            handleFreeRegister={handleFreeRegister}
            handleStopRegister={handleStopRegister}
            RowComponent={RowPatientValorationTable}
            isLoading={isLoading}
            activePage={activePage}
            handlePageChange={handlePageChange}
            hideNavigation={(firstVisible?.id == prevLastVisible?.id && activePage > 1) || hideNavigation}
            FilterComponent={() => (
              <FilterComponent
                control={control}
                errors={errors}
                handleSearch={handleSearchByYearMonthStatus}
                optionsYearRange={optionsYearRange}
              />
            )}
          />
      </div>
      <CustomModal
        show={modalIsOpen}
        close={toggleModal}
        title={`Descargar formato de valoración ${patientDataDownload ? `de ${patientDataDownload.general.name}` : ''
          }`}
      >
        <Row>
          <Col xs="12" className="d-flex justify-content-around">
            <Button
              className="btn-custom-cancel"
              color=""
              onClick={toggleModal}
            >
              Cancelar
            </Button>
            <PDFDownloadLink
              document={<PatientValorationPdf data={patientDataDownload} />}
              fileName={`${patientDataDownload
                ? patientDataDownload.general.name
                : 'Registro de paciente'
                }.pdf`}
            >
              {({ blob, loading }) => (loading || blob.size < 650 ? (
                <Spinner />
              ) : (
                <Button className="btn-custom" color="" onClick={toggleModal}>
                  Descargar
                </Button>
              ))}
            </PDFDownloadLink>
          </Col>
        </Row>
      </CustomModal>
    </>
  );
};

const FilterComponent = ({
  control,
  errors,
  handleSearch,
  optionsYearRange,
}) => {
  return (
    <Container className="filter-patient-list">
      <Row>
        <Col xs="12" md="6" lg="6" xl="3" className="col-no-padding center-label">
          <InputFormGroup
            name="yearFilter"
            label="Año"
            type="select"
            inputSearch
            control={control}
            errors={errors?.yearFilter}
            labelSize={4}
            inputSize={8}
            placeHolder="Seleccionar año"
            search={false}
            options={optionsYearRange}
          />
        </Col>
        <Col xs="12" md="6" lg="6" xl="3" className="col-no-padding center-label">
          <InputFormGroup
            name="monthFilter"
            label="Mes"
            type="select"
            inputSearch
            labelSize={4}
            inputSize={8}
            control={control}
            errors={errors?.monthFilter}
            placeHolder="Seleccionar mes"
            search={false}
            options={monthOptions}
            readOnly={!control.getValues('yearFilter')}
          />
        </Col>
        <Col xs="12" md="7" lg="6" xl="4" className="col-no-padding center-label">
          <InputFormGroup
            name="typeFilter"
            label="Usuarios"
            type="select"
            inputSearch
            labelSize={5}
            inputSize={7}
            control={control}
            errors={errors?.typeFilter}
            placeHolder="Seleccionar"
            search={false}
            options={typeUserOptions}
          />
        </Col>
        <Col xs="12" md="5" lg="6" xl="2" className="col-no-padding">
          <Button
            className="btn-custom btn-search"
            color=""
            onClick={() => handleSearch()}
          >
            Confirmar
          </Button>
        </Col>
      </Row>
    </Container>
  );
};

FilterComponent.propTypes = {
  control: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  handleSearch: PropTypes.func.isRequired,
  optionsYearRange: PropTypes.array.isRequired,
};

PatientList.propTypes = {
  history: PropTypes.object.isRequired,
};

export default authHOC(PatientList, ['Administrador', 'Editor', 'Colaborador']);
