/**
 * 1 = lunes
 * 2 = martes
 * 3 = miercoles
 * etc.
 */
export const SchedulesType = {
  1: {
    1: '07:50am-08:30am',
    2: '08:30am-09:10am',
    3: '09:10am-09:50am',
    4: '09:50am-10:30am',
    5: '10:30am-11:10am',
    6: '11:10am-11:50am',
    7: '11:50am-12:30pm',
    8: '12:30pm-01:10pm',
    9: '01:10pm-01:50pm',
    10: '01:50pm-02:30pm',
    11: '02:30pm-03:10pm',
    12: '03:10pm-03:50pm',
    13: '03:50pm-04:30pm',
    14: '04:30pm-05:10pm',
    15: '05:10pm-05:50pm',
    16: '05:50pm-06:30pm',
    17: '06:30pm-07:10pm',
    18: '07:10pm-07:50pm',
  },
  2: {
    1: '07:50am-08:30am',
    2: '08:30am-09:10am',
    3: '09:10am-09:50am',
    4: '09:50am-10:30am',
    5: '10:30am-11:10am',
    6: '11:10am-11:50am',
    7: '11:50am-12:30pm',
    8: '12:30pm-01:10pm',
    9: '01:10pm-01:50pm',
    10: '01:50pm-02:30pm',
    11: '02:30pm-03:10pm',
    12: '03:10pm-03:50pm',
    13: '03:50pm-04:30pm',
    14: '04:30pm-05:10pm',
    15: '05:10pm-05:50pm',
    16: '05:50pm-06:30pm',
    17: '06:30pm-07:10pm',
    18: '07:10pm-07:50pm',
  },
  3: {
    1: '07:50am-08:30am',
    2: '08:30am-09:10am',
    3: '09:10am-09:50am',
    4: '09:50am-10:30am',
    5: '10:30am-11:10am',
    6: '11:10am-11:50am',
    7: '11:50am-12:30pm',
    8: '12:30pm-01:10pm',
    9: '01:10pm-01:50pm',
    10: '01:50pm-02:30pm',
    11: '02:30pm-03:10pm',
    12: '03:10pm-03:50pm',
    13: '03:50pm-04:30pm',
    14: '04:30pm-05:10pm',
    15: '05:10pm-05:50pm',
    16: '05:50pm-06:30pm',
    17: '06:30pm-07:10pm',
    18: '07:10pm-07:50pm',
  },
  4: {
    1: '07:50am-08:30am',
    2: '08:30am-09:10am',
    3: '09:10am-09:50am',
    4: '09:50am-10:30am',
    5: '10:30am-11:10am',
    6: '11:10am-11:50am',
    7: '11:50am-12:30pm',
    8: '12:30pm-01:10pm',
    9: '01:10pm-01:50pm',
    10: '01:50pm-02:30pm',
    11: '02:30pm-03:10pm',
    12: '03:10pm-03:50pm',
    13: '03:50pm-04:30pm',
    14: '04:30pm-05:10pm',
    15: '05:10pm-05:50pm',
    16: '05:50pm-06:30pm',
    17: '06:30pm-07:10pm',
    18: '07:10pm-07:50pm',
  },
  5: {
    1: '07:50am-08:30am',
    2: '08:30am-09:10am',
    3: '09:10am-09:50am',
    4: '09:50am-10:30am',
    5: '10:30am-11:10am',
    6: '11:10am-11:50am',
    7: '11:50am-12:30pm',
    8: '12:30pm-01:10pm',
    9: '01:10pm-01:50pm',
    10: '01:50pm-02:30pm',
    11: '02:30pm-03:10pm',
    12: '03:10pm-03:50pm',
    13: '03:50pm-04:30pm',
    14: '04:30pm-05:10pm',
    15: '05:10pm-05:50pm',
    16: '05:50pm-06:30pm',
    17: '06:30pm-07:10pm',
    18: '07:10pm-07:50pm',
  },
  6: {
    1: '08:00am-08:30am',
    2: '08:30am-09:10am',
    3: '09:10am-09:50am',
    4: '09:50am-10:30am',
    5: '10:30am-11:10am',
    6: '11:10am-11:50am',
    7: '11:50am-12:30pm',
    8: '12:30pm-01:10pm',
    9: '01:10pm-01:50pm',
    10: '01:50pm-02:30pm',
    11: '02:30pm-03:10pm',
    12: '03:10pm-03:50pm',
    13: '03:50pm-04:30pm',
    14: '04:30pm-05:10pm',
    15: '05:10pm-05:50pm',
    16: '05:50pm-06:30pm',
    17: '06:30pm-07:10pm',
    18: '07:10pm-07:50pm',
  },
};
