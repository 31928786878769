import React, { useEffect, useState } from 'react';
import Input from 'reactstrap/lib/Input';
import PropTypes from 'prop-types';


const iconsFolder = require.context('../assets/hammersmith-icons', true);

const HammersmithField = ({
  indicador,
  seccionId,
  clave,
  register,
  setResultado,
  lastValues,
  setValue,
  setLastValues,
  getValues,
  readOnly,
}) => {
  const [selected, setSelected] = useState(-1);
  const getColorIndicator = (estatus) => {
    switch (estatus) {
      case 0:
        return 'default';
      case 1:
        return 'green';
      case 2:
        return 'red';
      default:
        return 'default';
    }
  };

  const getDiIndicator = (totalDi) => {
    const elements = [];
    for (let i = 0; i < totalDi; i += 1) {
      elements.push('D I');
    }
    return (
      <div style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-around',
        margin: '0 2em',
      }}
      >
        {elements.map((text, index) => (
          <div key={index}>
            <span>{text}</span>
          </div>
        ))}
      </div>
    );
  };

  const getIcons = (iconsIds = null, textIcons = null) => {
    const elements = [];
    for (let i = 0; i < iconsIds?.length; i += 1) {
      elements.push('');
    }
    return (
      <div style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-around',
        margin: '0 2em',
      }}
      >
        {elements.map((text, index) => (
          <div key={index} style={{ flex: '1' }}>
            {iconsIds && (
              <>
                <img
                  src={iconsFolder(
                    `./${iconsIds[index]}.png`,
                  )}
                  alt={`icono formulario hammersmith ${iconsIds[index]}`}
                  className="icono-indicador"
                />
                <Input
                  key={indicador.indicador}
                  type="radio"
                  name={`cuestionario.${clave}.secciones[${seccionId}].valorIndicador`}
                  innerRef={register}
                  value={iconsIds?.length > 1 ? (indicador.valor + '-' + iconsIds[index]) : indicador.valor}
                  className={`input-radio ${clave}-${seccionId} radio-${getColorIndicator(
                    indicador.status,
                  )}`}
                  style={{ margin: '0.5rem auto' }}
                  onChange={setResultado}
                  onClick={(e) => {
                    const valorSel = lastValues?.cuestionario[clave]?.secciones[seccionId]?.valorIndicador;
                    if (valorSel === e.target.value) {
                      setValue(`cuestionario.${clave}.secciones[${seccionId}].valorIndicador`, 0);
                    }
                    getValues()
                    setLastValues(getValues());
                    setResultado();
                  }}
                  disabled={readOnly}
                />
              </>
            )}
            {textIcons && <span dangerouslySetInnerHTML={{ __html: textIcons[index] }}></span>}
          </div>
        ))}
      </div>
    );
  };

  useEffect(() => {
    const values = getValues();
    const indicadorValue = values.cuestionario[clave].secciones[seccionId].valorIndicador;
    const value = indicadorValue?.includes('-') ? indicadorValue?.split('-')[0] : indicadorValue
    setSelected(Number(value));
  });

  return (
    // eslint-disable-next-line
    <td
      className={`${selected === indicador.valor ? 'selected-field' : ''} ${indicador.indicador ? indicador?.size === 'middle' ? 'size-middle' : 'p-1 con-info' : 'sin-info'} ${indicador?.iconsIds && 'selected-files__check'}`}
      onClick={(e) => {
        const input = e.target.children[0];
        // eslint-disable-next-line
        input?.click();
      }}
    >
      {!indicador?.iconsIds && (
        <Input
          key={indicador.indicador}
          type="radio"
          name={`cuestionario.${clave}.secciones[${seccionId}].valorIndicador`}
          innerRef={register}
          value={indicador.valor}
          className={`input-radio ${clave}-${seccionId} radio-${getColorIndicator(
            indicador.status,
          )}`}
          onChange={setResultado}
          onClick={(e) => {
            const valorSel = lastValues?.cuestionario[clave]?.secciones[seccionId]?.valorIndicador;
            if (valorSel === e.target.value) {
              setValue(`cuestionario.${clave}.secciones[${seccionId}].valorIndicador`, 0);
            }
            setLastValues(getValues());
            setResultado();
          }}
          disabled={readOnly}
        />
      )}
      <div dangerouslySetInnerHTML={{ __html: indicador.indicador }}></div>
      {/* {indicador.indicador } */}
      {indicador.iconId && (
        <>
          {!indicador?.iconsIds && (
            <img
              src={iconsFolder(
                `./${indicador?.iconId}.png`,
              )}
              alt={`icono formulario hammersmith ${indicador?.iconId}`}
              className="icono-indicador"
            />
          )}
          {indicador?.iconsIds && (getIcons(indicador?.iconsIds, indicador?.textIcons))}
          {indicador.DI && getDiIndicator(indicador.DI)}
        </>
      )}
      <div dangerouslySetInnerHTML={{ __html: indicador.indicador2 }}></div>
    </td>

  );
};

HammersmithField.propTypes = {
  indicador: PropTypes.object.isRequired,
  seccionId: PropTypes.number.isRequired,
  clave: PropTypes.string.isRequired,
  register: PropTypes.func.isRequired,
  setResultado: PropTypes.func.isRequired,
  lastValues: PropTypes.array.isRequired,
  setValue: PropTypes.func.isRequired,
  setLastValues: PropTypes.func.isRequired,
  getValues: PropTypes.func.isRequired,
  readOnly: PropTypes.bool.isRequired,
};

export default HammersmithField;
